import React from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { getUser } from "../helpers/utils";

export default function IntercomComponent({
  children,
  customPadding,
}: {
  children: React.ReactNode;
  customPadding?: number;
}) {
  const user = getUser();
  console.log(user);

  Intercom({
    app_id: "cd1yir7d",
    user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: user.fullName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    vertical_padding: customPadding,
    user: user,
    country: user.country,
    stripe_id: user.subscription?.customerId,
    stripe_plan: user.subscription?.tier,
    experience: user.experience?.toString(),
    is_active: user.isActive?.toString(),
    is_email_notification_on: user.isEmailNotificationOn?.toString(),
    total_payments: user.totalPayments?.toString(),
    total_saved_products: user.totalSavedProducts?.toString(),
    total_sellers: user.totalSellers?.toString(),
    total_devices: user.totalDevices?.toString(),
    active_sellers: user.activeSellersCount?.toString(),
    paused_sellers: user.pausedSellersCount?.toString(),
    favorite_sellers: user.favoriteSellersCount?.toString(),
    last_active: user.lastActive?.toString(),
    last_password_changed: user.lastPasswordChanged?.toString(),
    last_email_notification: user.lastEmailNotification?.toString(),
  });

  //   Intercom.setBottomPadding(120);

  return <>{children}</>;
}
