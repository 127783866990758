import { useState } from "react";
import { useToast } from "../../@/components/ui/use-toast";
import CustomButton from "../../components/Button";
import Modal from "../../components/Modal";
import { api } from "../../helpers/api";
import useStripeSession from "../../helpers/hooks/useSubscription";
import { getUser } from "../../helpers/utils";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/check-mark.svg";
import { Button } from "../../@/components/ui/button";
import { ColorBoxIcon } from "../product-feed/components/ColorBoxIcon";

export function PaymentUpdateSuccessModal({ isOpen, onClose }) {
  return (
    <Modal open={isOpen} title={""} noClose>
      <div className="flex flex-col items-center">
        <div className="rounded-2xl flex-col justify-center self-center items-center gap-6 inline-flex">
          <div className="p-3 bg-[#e8ffe9] rounded-[140px] justify-start items-center gap-2.5 inline-flex">
            <div className="p-7 bg-[#88d18b] rounded-[90px] justify-start items-center gap-2.5 flex">
              <CheckMarkIcon className="w-7 h-7 [&_path]:stroke-slate-900" />
            </div>
          </div>
          <div className="self-stretch h-14 flex-col justify-start items-center gap-3 flex">
            <div className="text-slate-900 text-xl font-medium font-grotesk leading-normal">
              Payment updated!
            </div>
            <div className="flex-col justify-center items-center gap-2 flex">
              <div className="ext-center text-slate-500 text-sm font-medium leading-tight">
                Your payment method has been updated!
              </div>
            </div>
          </div>
        </div>

        <Button
          onClick={() => onClose()}
          variant="outline"
          className="!h-10 mt-4 w-full !text-slate-900 !text-sm font-medium"
        >
          Dismiss
        </Button>
      </div>
    </Modal>
  );
}
export function PastDueModal({ isOpen, onClose }) {
  const { toast } = useToast();
  const user = getUser();
  const [stripeUpdateBillingLoading, setStripeUpdateBillingLoading] =
    useState(false);
  const {
    handleGetStripeSession,
    stripeLoading,
    nextPackage,
    activeSubscription,
    isLoading,
    refetch,
  } = useStripeSession();

  const handleUpdateBilling = () => {
    setStripeUpdateBillingLoading(true);
    api
      .customerStripePortal(user.id)
      .then((res) => {
        onClose();
        // add flag in localstorage to show payment success modal
        localStorage.setItem("paymentUpdateSuccess", "true");
        window.location.href = res.url;
        setStripeUpdateBillingLoading(false);
        refetch();
      })
      .catch(() => {
        toast({
          description: "Failed to edit billing. Please try again.",
          variant: "destructive",
        });
        setStripeUpdateBillingLoading(false);
        refetch();
      });
  };

  return (
    <Modal open={isOpen} title={"Payment Failed"} noClose>
      <div className="flex flex-col gap-3">
        <h2 className="text-sm font-medium text-slate-500 mt-3">
          Your payment has failed, and monitoring is paused. Update your payment
          method before losing access.
        </h2>
        <div className="flex flex-col gap-1">
          <span className="text-slate-900 text-sm font-medium">
            Subscription Status
          </span>

          <div className="w-fit border border-slate200 bg-slate50 rounded-[6px] px-1.5 py-[1px] flex items-center gap-1">
            <ColorBoxIcon bg="bg-red-50" border="border-red-900" />
            <span className="text-[#0F172A] text-[12px] font-medium">
              Past Due
            </span>
          </div>
        </div>

        <CustomButton
          onClick={() => {
            handleUpdateBilling();
          }}
          loading={stripeUpdateBillingLoading}
          disabled={stripeUpdateBillingLoading}
          className="px-4 w-full"
        >
          Update payment method
        </CustomButton>
      </div>
    </Modal>
  );
}
