import { Button } from "../../../../@/components/ui/button";
import { ReactComponent as ExternalLinkIcon } from "../../../../assets/icons/external-link.svg";

export function EmptySellerFeed() {
  return (
    <div className="flex-1 flex justify-center items-center">
      <div className="flex flex-col items-center gap-3">
        <h2 className="text-center font-grotesk text-[28px] font-medium leading-[33.6px]">
          Tutorial
        </h2>

        <div className="text-center text-slate-900 tracking-[-0.3px] leading-[25.5px] text-[15px]">
          Watch our video on how to get started and what to expect.
        </div>
        <a
          href="https://help.stealthseller.co/en/articles/9332361-getting-started"
          target="_blank"
          rel="noreferrer"
        >
          <Button className="w-fit gap-1.5">
            <ExternalLinkIcon className="[&_path]:stroke-white" />
            Watch video
          </Button>
        </a>
      </div>
    </div>
  );
}
