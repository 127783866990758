import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { BookmarkButton } from "./BookmarkButton";
import { StatusButton } from "./StatusButton";
import { Button } from "../../../../@/components/ui/button";
import { Tag } from "./Tag";
import {
  FavoriteTag,
  favoriteTagInfo,
} from "../../../sellers/components/FavoriteTag";
import { SellerListRowAction } from "../../../sellers/components/SellerListRowAction";
import { useState } from "react";
import { api } from "../../../../helpers/api";
import { toast } from "../../../../@/components/ui/use-toast";
import { getUser } from "../../../../helpers/utils";
import hotToast from "react-hot-toast";

export function SellerCard({
  selected,
  showTags,
  seller,
  onSelect,
  onUpdate,
  onView,
  onRemove,
  onEdit,
}: {
  selected: boolean;
  showTags: boolean;
  seller: any;
  onSelect: () => void;
  onUpdate: () => void;
  onView: () => void;
  onRemove: () => void;
  onEdit?: () => void;
}) {
  const user = getUser();

  const handlePause = async (seller) => {
    try {
      hotToast.loading("Pausing seller...");
      const response = await api.updateSeller(user.id, {
        id: seller.id,
        status: "P",
      });
      hotToast.dismiss();
      if ([200, 201].includes(response.status)) {
        toast({
          title: "Seller paused successfully",
        });
        onUpdate();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleActive = async (seller) => {
    try {
      hotToast.loading("Activating seller...");
      const response = await api.updateSeller(user.id, {
        id: seller.id,
        status: "A",
      });
      hotToast.dismiss();
      if ([200, 201].includes(response.status)) {
        toast({
          title: "Seller activated successfully",
        });
        onUpdate();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleBookmark = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const hasFavorite = seller?.tags?.find(
        (tag) => tag.toLowerCase() === favoriteTagInfo.name.toLowerCase()
      );
      let payload: any = {
        id: seller.id,
      };
      const tags = (seller.tags || []).filter((e) => e);
      if (hasFavorite) {
        payload["tags"] = tags.filter(
          (tag) => tag.toLowerCase() !== favoriteTagInfo.name.toLowerCase()
        );
      } else {
        payload["tags"] = [...tags, favoriteTagInfo.name];
      }
      // update seller
      const response = await api.updateSeller(user.id, payload);
      if ([200, 201].includes(response.status)) {
        onUpdate();
      }
    } catch (error: any) {
      console.log(error);
      toast({
        title: "Something went wrong",
        description: error?.response?.data?.message,
      });
    }
  };

  const isBookmarked = !!seller?.tags?.find(
    (tag) => tag.toLowerCase() === favoriteTagInfo.name.toLowerCase()
  );

  const tags = seller?.tags?.filter(
    (tag) => tag && tag.toLowerCase() !== favoriteTagInfo.name.toLowerCase()
  );

  return (
    <div
      className={`border border-slate200 shadow-field outline-offset-2 p-3 rounded-md `}
      style={{
        outline: selected ? "2px solid #0F172A" : "none",
      }}
      role="button"
      onClick={onSelect}
    >
      <div className="flex justify-between items-start gap-5 mb-1">
        <div className="flex flex-col">
          <span className="text-sm font-semibold text-slate-900 leading-5">
            {seller?.nickName || seller?.sellerName}
          </span>
          <span className="text-xs font-medium text-slate-600 leading-5">
            {seller?.sellerId}
          </span>
        </div>
        <div className="flex gap-1 items-center">
          {seller?.totalUnreadProducts > 0 && (
            <div className="rounded-[6px] bg-slate-900 text-xs font-medium py-[0.25rem] px-1.5 text-white">
              {seller?.totalUnreadProducts}
            </div>
          )}
          <SellerListRowAction
            onView={onView}
            onPause={() => handlePause(seller)}
            onActive={() => handleActive(seller)}
            onRemove={onRemove}
            status={seller?.status}
            onEdit={onEdit}
            seller={seller}
          />
        </div>
      </div>
      <div className="flex items-center gap-1">
        <BookmarkButton onClick={handleBookmark} bookmarked={isBookmarked} />
        <StatusButton status={seller?.status} />
      </div>
      {showTags && tags?.length > 0 && (
        <div className="flex flex-wrap items-center gap-1 mt-[9px]">
          {tags?.map((tag, i) =>
            tag.toLowerCase() === favoriteTagInfo.name.toLowerCase() ? null : (
              <Tag key={i} text={tag} />
            )
          )}
        </div>
      )}
    </div>
  );
}
