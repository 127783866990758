import { AlertDialog, Flex } from "@radix-ui/themes";
import { Button } from "../../../@/components/ui/button";
import Spinner from "../../../components/Spinners";
import { useState } from "react";
import { api } from "../../../helpers/api";
import { getUser } from "../../../helpers/utils";
import { toast } from "../../../@/components/ui/use-toast";
import { ReactComponent as SellersIcon } from "../../../assets/icons/sellers.svg";

export function DeleteSellerConfirm({ open, onClose, seller, onSuccess }) {
  const [removing, setRemoving] = useState(false);
  const user = getUser();

  const handleConfirm = async (e) => {
    // remove seller
    e.stopPropagation();
    e.preventDefault();
    try {
      setRemoving(true);
      const response = await api.removeSeller(user.id, seller.id);
      setRemoving(false);
      if ([200, 201].includes(response.status)) {
        onSuccess();
        onClose();
        toast({
          title: `${
            seller?.nickName || seller?.sellerName || "Seller"
          } has been removed.`,
          description: "Your changes have been successfully saved.",
        });
      }
    } catch (e) {}
  };
  return (
    <AlertDialog.Root open={open} onOpenChange={onClose}>
      <AlertDialog.Content maxWidth="450px">
        <AlertDialog.Title className="!font-grotesk text-xl font-medium leading-[24px]">
          Delete seller
        </AlertDialog.Title>
        <AlertDialog.Description size="2">
          <span className="text-[#64748B]">
            Are you sure you want to permanently delete this seller? This will
            also remove any bookmarked products from this seller.
          </span>
          <div className="flex items-center gap-2 mt-2">
            <Button
              variant={"outline"}
              color="gray"
              onClick={() => {}}
              className={`px-1.5 h-6 flex gap-1 items-center rounded-[6px] bg-slate-50 border border-slate200`}
            >
              <SellersIcon className="w-3 h-3 [&_path]:stroke-slate-400" />
              <span className="text-slate-400 text-xs font-medium">
                {seller?.nickName || seller?.sellerName}
              </span>
            </Button>
            <div className="px-1.5 h-6 flex gap-1 items-center rounded-[6px] bg-slate-50 border border-slate200">
              <span className="text-slate-400 text-xs font-medium">
                {seller?.sellerId}
              </span>
            </div>
          </div>
        </AlertDialog.Description>

        <Flex gap="3" mt="4" justify="end">
          <AlertDialog.Cancel>
            <Button variant="outline" color="gray" onClick={onClose}>
              Go back
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="destructive"
              color="red"
              className="gap-1.5"
              onClick={handleConfirm}
            >
              Yes, remove
              {removing && <Spinner />}
            </Button>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}
