import { SearchInput } from "../../../components/Search";

import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { useState } from "react";
import { CategorySelect } from "../../product-feed/Feed/components/CategorySelect";
import { NumberFilterInput } from "../../product-feed/Feed/components/NumberFilterInput";
import { useSavedProductFeed } from "../context/saved-product-feed-context";
import { FILTER_NAME } from "../../product-feed/Feed/constant";
import { DateSelect } from "../../product-feed/Feed/components/DateSelect";
import { FilterModal } from "../../product-feed/Feed/components/FilterModal";
import moment from "moment";
import { BooleanFilterInput } from "../../product-feed/Feed/components/BooleanFilterInput";
import { StoreFrontFilter } from "../../product-feed/Feed/components/StoreFrontFilter";

export function FilterView() {
  const {
    productFilter,
    setProductFilter,
    onClearFilter,
    onClearAllFilters,
    onApply,
    onSearch,
    searchState,
    total,
    appliedFilter,
    setAppliedFilter,
  } = useSavedProductFeed() as any;
  const [filtersToShow, setFiltersToShow] = useState<string[]>([]);

  const clearFilter = (filtersToShow, productFilter) => {
    // need to clear filters other than the selected ones
    let newFilters: any = {};
    filtersToShow.forEach((filter) => {
      newFilters[filter] =
        productFilter[filter] || filter === "categories"
          ? []
          : [
              FILTER_NAME.AMAZON_ON_LISTING,
              FILTER_NAME.NEWLY_POSTED,
              FILTER_NAME.STOREFRONT_FULFILLMENT_METHOD,
            ].includes(filter)
          ? undefined
          : {};
    });
    setProductFilter(newFilters);
  };

  const handleChange = (filter) => {
    let newFiltersToShow = [...filtersToShow];
    if (filtersToShow.includes(filter.name)) {
      newFiltersToShow = filtersToShow.filter((item) => item !== filter.name);
    } else {
      newFiltersToShow.push(filter.name);
    }
    setFiltersToShow(newFiltersToShow);
    clearFilter(newFiltersToShow, productFilter);
  };

  const handleClearAll = () => {
    setProductFilter({});
    setFiltersToShow([]);
    onClearAllFilters();
  };

  const renderFilters = () => {
    return (
      <>
        {filtersToShow.includes(FILTER_NAME.BUY_BOX) && (
          <NumberFilterInput
            label="Buy Box"
            onChange={(filter) =>
              setProductFilter((prev) => ({ ...prev, buyBox: filter }))
            }
            value={productFilter?.buyBox || {}}
            suffix="prices"
            name="BuyBoxPrice"
            showDollar={true}
            onClear={() => {
              onClearFilter("BuyBoxPrice");
              setProductFilter((prev) => ({ ...prev, buyBox: {} }));
            }}
            onApply={() => {
              onApply({ ...productFilter?.buyBox, name: "BuyBoxPrice" });
            }}
            appliedValue={appliedFilter?.BuyBoxPrice}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.CATEGORIES) && (
          <CategorySelect
            value={productFilter?.categories}
            onChange={(value) =>
              setProductFilter((prev) => ({ ...prev, categories: value }))
            }
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, categories: [] }));
              onClearFilter("categories");
            }}
            onApply={() => {
              onApply({
                name: "categories",
                value: productFilter?.categories,
              });
            }}
            appliedValue={appliedFilter?.categories?.value || []}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.OFFERS) && (
          <NumberFilterInput
            label="Offers"
            onChange={(filter) =>
              setProductFilter((prev) => ({ ...prev, offers: filter }))
            }
            suffix="offers"
            name="OffersCount"
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, offers: {} }));
              onClearFilter("OffersCount");
            }}
            value={productFilter.offers}
            onApply={() => {
              onApply({ ...productFilter?.offers, name: "OffersCount" });
            }}
            appliedValue={appliedFilter?.OffersCount}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.SALES_RANK) && (
          <NumberFilterInput
            label="Sales Rank"
            onChange={(filter) =>
              setProductFilter((prev) => ({ ...prev, salesRank: filter }))
            }
            suffix="sales rank"
            name="SalesRank"
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, salesRank: {} }));
              onClearFilter("SalesRank");
            }}
            value={productFilter.salesRank}
            onApply={() => {
              onApply({ ...productFilter?.salesRank, name: "SalesRank" });
            }}
            appliedValue={appliedFilter?.SalesRank}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.MONTHLY_SOLD) && (
          <NumberFilterInput
            label="Monthly Sales"
            onChange={(filter) =>
              setProductFilter((prev) => ({ ...prev, monthlySold: filter }))
            }
            suffix="sales"
            name="MonthlySales"
            value={productFilter.monthlySold}
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, monthlySold: {} }));
              onClearFilter("MonthlySales");
            }}
            onApply={() => {
              onApply({
                ...productFilter?.monthlySold,
                name: "MonthlySales",
              });
            }}
            appliedValue={appliedFilter?.MonthlySales}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.TIME_POSTED) && (
          <DateSelect
            name="TimePosted"
            onDateChange={(filter) => {
              setProductFilter((prev) => ({ ...prev, timePosted: filter }));
              if (filter.startDate && filter.endDate) {
                onApply({
                  name: "timePosted",
                  startDate: moment(
                    productFilter?.timePosted?.startDate
                  ).format(),
                  endDate: moment(productFilter?.timePosted?.endDate).format(),
                });
              }
            }}
            value={productFilter.timePosted}
            onApply={() => {
              if (
                productFilter?.timePosted?.startDate &&
                productFilter?.timePosted?.endDate
              ) {
                onApply({
                  name: "timePosted",
                  startDate: productFilter?.timePosted?.startDate,
                  endDate: productFilter?.timePosted?.endDate,
                });
              }
            }}
            appliedValue={appliedFilter?.timePosted}
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, timePosted: {} }));
              onClearFilter("timePosted");
            }}
          />
        )}

        {filtersToShow.includes(FILTER_NAME.NEWLY_POSTED) && (
          <BooleanFilterInput
            // name="newlyPosted"
            label="Newly Posted"
            onChange={(filter) => {
              console.log("ccccc", filter);
              setProductFilter((prev) => ({ ...prev, newlyPosted: filter }));
            }}
            value={productFilter.newlyPosted}
            defaultLabel="posted"
            onApply={() => {
              onApply({
                value: productFilter?.newlyPosted,
                name: "newlyPosted",
              });
            }}
            appliedValue={appliedFilter?.newlyPosted}
            onClear={() => {
              setProductFilter((prev) => ({ ...prev, newlyPosted: undefined }));
              onClearFilter("newlyPosted");
            }}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.AMAZON_ON_LISTING) && (
          <BooleanFilterInput
            // name="newlyPosted"
            label="Amazon Listing"
            onChange={(filter) => {
              console.log("ccccc", filter);
              setProductFilter((prev) => ({ ...prev, amazonListing: filter }));
            }}
            defaultLabel="listing"
            value={productFilter.amazonListing}
            onApply={() => {
              onApply({
                value: productFilter?.amazonListing,
                name: "amazonListing",
              });
            }}
            appliedValue={appliedFilter?.amazonListing}
            onClear={() => {
              setProductFilter((prev) => ({
                ...prev,
                amazonListing: undefined,
              }));
              onClearFilter("amazonListing");
            }}
          />
        )}
        {filtersToShow.includes(FILTER_NAME.STOREFRONT_FULFILLMENT_METHOD) && (
          <StoreFrontFilter
            // name="newlyPosted"
            onChange={(filter) => {
              console.log("ccccc", filter);
              setProductFilter((prev) => ({
                ...prev,
                storefrontFulfillmentMethod: filter,
              }));
            }}
            value={productFilter.storefrontFulfillmentMethod}
            onApply={() => {
              onApply({
                value: productFilter?.storefrontFulfillmentMethod,
                name: "storefrontFulfillmentMethod",
              });
            }}
            appliedValue={appliedFilter?.storefrontFulfillmentMethod}
            onClear={() => {
              setProductFilter((prev) => ({
                ...prev,
                storefrontFulfillmentMethod: undefined,
              }));
              onClearFilter("storefrontFulfillmentMethod");
            }}
          />
        )}

        {filtersToShow.length > 0 && (
          <button
            onClick={handleClearAll}
            className="text-[#09090B] hover:text-[#09090B]/80 text-sm font-medium flex items-center gap-1.5 !h-fit"
          >
            Clear all
            <CloseIcon className="w-4 h-4" />
          </button>
        )}
      </>
    );
  };

  return (
    <>
      <div className="pl-3 pr-3 md:pr-[18px]">
        <div className="flex justify-between items-stretch md:gap-2 lg:gap-20">
          <div className="flex gap-2 flex-wrap">
            <SearchInput
              onChange={(e) => onSearch(e.target.value)}
              value={searchState.keyword}
            />
            <div className="hidden lg:flex gap-2 items-center flex-wrap">
              {renderFilters()}
            </div>
          </div>

          <div className="flex flex-col justify-between">
            <FilterModal
              onClear={handleClearAll}
              filters={filtersToShow}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      {filtersToShow.length > 0 && (
        <div className="h-[70px] ml-3 pr-3 md:pr-[18px] mt-3 lg:hidden flex gap-2 items-center whitespace-nowrap overflow-x-scroll pb-2">
          {renderFilters()}
        </div>
      )}
    </>
  );
}
