import { useNavigate } from "react-router-dom";

export function EmptyFeed() {
  return (
    <div className="flex-1 flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-3">
        <h2 className="text-center font-grotesk text-[28px] font-medium leading-[33.6px]">
          No Products Saved Yet
        </h2>
      </div>
    </div>
  );
}
