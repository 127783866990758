import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import { Toaster } from "./@/components/ui/toaster";
import { Toaster as HotToaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId="811662228788-edfnbmk98irfgdafb5tnhfijv8tghn9i.apps.googleusercontent.com">
    <Theme>
      <React.StrictMode>
        <BrowserRouter>
          <div className="radix-themes">
            <Toaster />
            <HotToaster />
          </div>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Theme>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
