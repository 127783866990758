import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import ConfirmIcon from "../../../assets/icons/check.svg";
import { api } from "../../../helpers/api";
import { Layout } from "../../../components/Layout";
import FormField from "../../../components/FormField";
import Button from "../../../components/Button";
import { useToast } from "../../../@/components/ui/use-toast";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export default function ForgotPassword() {
  const [success, setSuccess] = useState(false);
  const { toast } = useToast();
  const toggleSuccess = () => setSuccess((prev) => !prev);

  return (
    <Layout>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        isInitialValid={false}
        onSubmit={(values, actions) => {
          api
            .forgotPassword({
              ...values,
              resetPasswordUrl: `${window.location.origin}/reset-password`,
            })
            .then((res) => {
              actions.setSubmitting(false);
              toggleSuccess();
            })
            .catch((err) => {
              actions.setSubmitting(false);
              toast({
                description: "Invalid email address. Please try again.",
                variant: "destructive",
              });
            });
        }}
      >
        {success ? (
          <ForgotPasswordSuccess toggleSuccess={toggleSuccess} />
        ) : (
          <ForgotPasswordFormContent />
        )}
      </Formik>
    </Layout>
  );
}

function ForgotPasswordSuccess({
  toggleSuccess,
}: {
  toggleSuccess: () => void;
}) {
  const { values }: { values: { email: string } } = useFormikContext();

  return (
    <div className="grid items-center h-full justify-center text-center lg:w-[380px] w-[calc(100vw-2rem)] mx-auto">
      <div className="grid gap-[1.5rem]">
        <img
          src={ConfirmIcon}
          alt="confirm"
          className="w-[80px] h-[80px] mx-auto"
        />
        <div className="grid gap-4">
          <h1 className="text-[1.25rem]">Check your email</h1>
          <p className="font-[400] font-[Inter]">
            A link to reset your password has been sent to your email{" "}
            <span className="font-[600]">({values.email})</span>. Please check
            your inbox.
          </p>
        </div>
        <div className="text-center">
          <span>Didn’t receive an email?</span>{" "}
          <button className="underline" onClick={toggleSuccess}>
            Resend
          </button>
        </div>
      </div>
    </div>
  );
}

function ForgotPasswordFormContent() {
  const { isValid, isSubmitting } = useFormikContext();

  return (
    <div className="grid items-center h-full justify-center">
      <div className="grid">
        <Form className="lg:w-[380px] w-[calc(100vw-2rem)] grid gap-[1.75rem] mb-[1.5rem]">
          <div className="grid gap-3">
            <h1 className="text-[1.5rem]">Forgot password</h1>
            <p className="text-[0.875rem] text-[#475569]">
              Please provide your registered email below, and we'll send you a
              link to reset your password.
            </p>
          </div>
          <div className="grid gap-4">
            <FormField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
            />
          </div>
          <Button
            className="w-full"
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            Continue
          </Button>
        </Form>
      </div>
    </div>
  );
}
