import React, { useEffect, useState, useCallback } from "react";
import { api } from "../../../helpers/api";
import { convertCentToDollar, getUser } from "../../../helpers/utils";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { toast } from "../../../@/components/ui/use-toast";
import { NumberFilter } from "../../product-feed/SellerList/constant";
import useSavedProducts from "./useSavedProducts";

export const SavedProductFeedContext = React.createContext({});

export function SavedProductFeedProvider({ children }) {
  const [appliedFilter, setAppliedFilter] = useState<any>({});
  const [productFilter, setProductFilter] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const user = getUser();
  const {
    filters,
    updateFilters,
    onSearch,
    state,
    pagination,
    onPaginationChange,
    totalPages,
    products,
    isLoading,
    total,
    refetch,
  } = useSavedProducts();

  const {
    data: readProducts,
    isLoading: readProductsLoading,
    refetch: refetchReadProducts,
  } = useQuery(
    ["readProducts", user.id],
    () => api.fetchUserReadProducts(user.id),
    {
      enabled: !!user.id,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  const onApply = useCallback(
    (filter) => {
      // apply filter
      setAppliedFilter((prev) => ({ ...prev, [filter.name]: filter }));
      if (filter.name === "categories") {
        updateFilters({
          categories: filter.value,
        });
        return;
      }
      if (filter.name === "newlyPosted") {
        updateFilters({
          newlyPosted: filter.value,
        });
        return;
      }
      if (filter.name === "amazonListing") {
        updateFilters({
          amazonListing: filter.value,
        });
        return;
      }
      if (filter.name === "storefrontFulfillmentMethod") {
        updateFilters({
          storefrontFulfillmentMethod: filter.value,
        });
        return;
      }
      if (filter.name === "timePosted") {
        updateFilters({
          minTimePosted: filter.startDate
            ? moment(filter.startDate).format("YYYY-MM-DD")
            : filter.startDate,
          maxTimePosted: filter.endDate
            ? moment(filter.endDate).format("YYYY-MM-DD")
            : filter.endDate,
        });
        return;
      }
      // evaluate filter & set to useProducts
      // filter: { name: "", condition: "grater_than/less_than/between", fromValue: "100", toValue: "200" }
      const minField = `min${filter.name}`;
      const maxField = `max${filter.name}`;
      if (filter.condition === NumberFilter.BETWEEN) {
        updateFilters({
          [minField]: filter.fromValue,
          [maxField]: filter.toValue,
        });
      } else if (filter.condition === NumberFilter.GRATER_THAN) {
        updateFilters({
          [minField]: filter.fromValue,
        });
      } else if (filter.condition === NumberFilter.LESS_THAN) {
        updateFilters({
          [minField]: 0,
          [maxField]: filter.fromValue,
        });
      }
    },
    [updateFilters]
  );

  const onClearFilter = useCallback(
    (filterName) => {
      if (filterName === "categories") {
        updateFilters({
          categories: undefined,
        });

        setAppliedFilter((prev) => {
          delete prev[filterName];
          return { ...prev };
        });
        return;
      }
      if (
        [
          "amazonListing",
          "newlyPosted",
          "storefrontFulfillmentMethod",
        ].includes(filterName)
      ) {
        updateFilters({
          [filterName]: undefined,
        });
        setAppliedFilter((prev) => {
          delete prev[filterName];
          return { ...prev };
        });
        return;
      }
      if (filterName === "timePosted") {
        updateFilters({
          minTimePosted: undefined,
          maxTimePosted: undefined,
        });
        setAppliedFilter((prev) => {
          delete prev[filterName];
          return { ...prev };
        });
        return;
      }
      const minField = `min${filterName}`;
      const maxField = `max${filterName}`;
      updateFilters({
        [minField]: undefined,
        [maxField]: undefined,
      });
      setAppliedFilter((prev) => {
        delete prev[filterName];
        return { ...prev };
      });
    },
    [updateFilters]
  );

  const onClearAllFilters = useCallback(() => {
    updateFilters({}, true);
    setAppliedFilter({});
  }, [updateFilters]);

  const onSaveProduct = async (product: any) => {
    try {
      const response = await api.removeSavedProduct(user.id, product.id);
      if ([200, 201].includes(response.status)) {
        toast({
          title: "Product removed successfully",
        });
        refetch();
      }
    } catch (e) {
      console.log(e);
      toast({
        title: "Something went wrong, please try again later.",
      });
    }
  };

  const filterProducts = useCallback(
    (products) => {
      if (!products || !products.length) return [];
      let productList = [...products];
      if (state.keyword) {
        productList = productList.filter((product) => {
          return product.title
            .toLowerCase()
            .includes(state.keyword.toLowerCase());
        });
      }
      if (filters.categories) {
        productList = productList.filter((product) => {
          return filters.categories.includes(product.category);
        });
      }
      if (filters.minBuyBoxPrice) {
        // we will have values in cents so we need to convert it to dollars
        productList = productList.filter((product) => {
          const price = convertCentToDollar(Number(product.buyBoxPrice || 0));
          return price >= Number(filters.minBuyBoxPrice);
        });
      }
      if (filters.maxBuyBoxPrice) {
        productList = productList.filter((product) => {
          const price = convertCentToDollar(Number(product.buyBoxPrice || 0));
          return price <= Number(filters.maxBuyBoxPrice);
        });
      }
      if (filters.minOffersCount) {
        productList = productList.filter((product) => {
          return product.offersCount >= Number(filters.minOffersCount);
        });
      }
      if (filters.maxOffersCount) {
        productList = productList.filter((product) => {
          return product.offersCount <= Number(filters.maxOffersCount);
        });
      }
      if (filters.minSalesRank) {
        productList = productList.filter((product) => {
          return product.salesRank >= Number(filters.minSalesRank);
        });
      }
      if (filters.maxSalesRank) {
        productList = productList.filter((product) => {
          return product.salesRank <= Number(filters.maxSalesRank);
        });
      }
      if (filters.minMonthlySales) {
        productList = productList.filter((product) => {
          return product.monthlySales >= Number(filters.minMonthlySales);
        });
      }
      if (filters.maxMonthlySales) {
        productList = productList.filter((product) => {
          return product.monthlySales <= Number(filters.maxMonthlySales);
        });
      }
      if (filters.minTimePosted) {
        productList = productList.filter((product) => {
          return moment(product.timePosted).isSameOrAfter(
            filters.minTimePosted
          );
        });
      }
      if (filters.maxTimePosted) {
        productList = productList.filter((product) => {
          return moment(product.timePosted).isSameOrBefore(
            filters.maxTimePosted
          );
        });
      }
      return productList;
    },
    [filters, state.keyword]
  );
  const values = {
    loading,
    setLoading,
    productFilter,
    setProductFilter,
    onApply,
    onSearch,
    searchState: state,
    onClearFilter,
    onClearAllFilters,
    products: products || [],
    productsLoading: isLoading,
    pagination,
    onPaginationChange,
    totalPages,
    total,
    onSaveProduct,
    refetch,
    appliedFilter,
    setAppliedFilter,
    readProducts,
    readProductsLoading,
    refetchReadProducts,
  };

  return (
    <SavedProductFeedContext.Provider value={values}>
      {children}
    </SavedProductFeedContext.Provider>
  );
}

export function useSavedProductFeed() {
  const context = React.useContext(SavedProductFeedContext);
  if (context === undefined) {
    throw new Error("useTags must be used within a ProductFeedProvider");
  }
  return context;
}
