import { Button } from "../../../../@/components/ui/button";
import { PlusIcon } from "@radix-ui/react-icons";
import { Popover, Tooltip } from "@radix-ui/themes";
import { Input } from "../../../../@/components/ui/input";
import { useEffect, useState } from "react";
import { marketPlaceIds } from "../constant";
import { extractSellerIdFromUrl } from "../../../sellers/context/utils";
import { ErrorView } from "../../../../components/Error";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import { getUser } from "../../../../helpers/utils";
import { api } from "../../../../helpers/api";
import { toast } from "../../../../@/components/ui/use-toast";
import Spinner from "../../../../components/Spinners";
import { SellerProductsFoundModal } from "./SellerProductsFoundModal";

export function AddSellerPopup({
  totalActiveUsers,
  sellerLimit,
  onSuccess,
  onSelect,
  setOpenProductFoundModal,
}) {
  const [sellerId, setSellerId] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [saving, setSaving] = useState<boolean>(false);
  const user = getUser();
  const handleAddSeller = () => {};

  const handleSave = async () => {
    try {
      setSaving(true);
      let payload: any = {};
      const newSellerId = extractSellerIdFromUrl(sellerId as string, true);
      payload = {
        sellerId: newSellerId,
      };

      const API = api.addSeller;
      const res = await API(user.id, {
        ...payload,
      });
      setSaving(false);
      console.log(res);
      if ([200, 201].includes(res.status)) {
        if (res?.data?.seller?.id) {
          setOpenProductFoundModal(res.data);
        }
        toast({
          title: "New seller has been added.",
          description: "We’ll keep you updated.",
        });
        onSuccess();
      } else {
        setErrors(res.data);
      }
    } catch (error: any) {
      console.log(error);
      setSaving(false);
      const message = error?.response?.data?.message;
      if (message) {
        if (message.includes("nick name") || message.includes("Nickname")) {
          setErrors((prev) => ({
            ...prev,
            nickName: message.split(":")[1] || message,
          }));
        } else if (message.includes("Seller already exists")) {
          setErrors((prev) => ({
            ...prev,
            sellerId: message,
          }));
        } else {
          console.log(message);

          const { dismiss } = toast({
            title: message,
            description: "Please try again.",
            action: (
              <Button
                variant="outline"
                onClick={() => dismiss()}
                className="!text-slate-900 !bg-white text- hover:!bg-slate-50 border border-slate200 !h-8 !px-3 !py-1"
              >
                Try again
              </Button>
            ),
          });
        }
      }
      setSaving(false);
    }
  };

  const handleSellerIdChange = (e) => {
    const value = e.target.value;
    setSellerId(value);
    if (marketPlaceIds.find((id) => id === value)) {
      setErrors((prev) => ({
        ...prev,
        sellerId: "This is a marketplace ID",
      }));
    } else {
      const sellerId = extractSellerIdFromUrl(value);
      if (value.startsWith("https://") && !sellerId) {
        setErrors((prev) => ({
          ...prev,
          sellerId: "This does not contain a seller ID",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          sellerId: "",
        }));
      }
    }
  };
  return (
    <>
      <Popover.Root>
        <Popover.Trigger>
          <Button
            className="!p-0 !border-none"
            variant="outline"
            onClick={(e) => {
              setSellerId("");
              e.stopPropagation();
            }}
          >
            <Tooltip content={"Add a seller"}>
              <div className="border hover:!bg-black flex justify-center items-center bg-black !px-0 !py-0 !min-w-8 !h-8 rounded-[6px]">
                <PlusIcon className="w-4 h-4 [&_path]:stroke-white" />
              </div>
            </Tooltip>
          </Button>
        </Popover.Trigger>
        <Popover.Content
          className="!p-3 flex flex-col gap-1.5 items-center"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex gap-1.5 items-center">
            <div className="flex flex-col gap-1.5">
              <label>
                Seller ID<span>*</span>
              </label>
              <Input
                placeholder="Enter your Seller ID"
                className="!h-8"
                value={sellerId}
                name="sellerId"
                onChange={handleSellerIdChange}
              />
            </div>
            <div className="flex flex-col items-end gap-1.5">
              <div className="w-fit bg-[#F8FAFC] border border-[#E2E8F0] rounded-[6px] px-1.5 py-[2px] text-xs font-medium leading-[18px] text-[#0F172A]">
                {totalActiveUsers}/{sellerLimit}
              </div>
              <Button
                className="!shadow-none  w-fit !border-none justify-start !px-2 !py-1.5 !h-8"
                onClick={handleSave}
                disabled={!sellerId}
              >
                {saving ? <Spinner /> : "Add seller"}
              </Button>
            </div>
          </div>
          <div className="w-full text-left">
            {errors.sellerId ? (
              <ErrorView message={errors.sellerId} />
            ) : sellerId && extractSellerIdFromUrl(sellerId) !== sellerId ? (
              <div className="px-1.5 py-0.5 rounded-md justify-start items-center gap-1 flex">
                <InfoIcon className="w-3 h-3 [&_path]:stroke-[#334155]" />
                <div className="text-slate-700 text-xs font-medium leading-[18px]">
                  Found {extractSellerIdFromUrl(sellerId)} in URL
                </div>
              </div>
            ) : null}
          </div>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
