import { Button } from "../../../@/components/ui/button";
import { ReactComponent as ShowIcon } from "../../../assets/icons/show.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/chevron-down.svg";
import { useEffect, useState } from "react";
import { SearchInput } from "../../../components/Search";
import { ReactComponent as SellersIcon } from "../../../assets/icons/sellers.svg";
import { Pagination } from "./components/Pagination";
import { FilterView } from "./components/FilterView";
import { ProductFeedCard } from "../components/ProductFeedCard/ProductFeedCard";
import { useProductFeed } from "../context/product-feed-context";
import { EmptySellerFeed } from "./components/EmptySellerFeed";
import { EmptyFeed } from "./components/EmptyFeed";
import { getUser } from "../../../helpers/utils";
import Spinner from "../../../components/Spinners";
import { EmptyProductFeed } from "./components/EmptyProductFeed";
import { api } from "../../../helpers/api";
import { useToast } from "../../../@/components/ui/use-toast";

export function Feed({ onOpenSellerList }: { onOpenSellerList?: () => void }) {
  const {
    selectedSeller,
    sellersLoading,
    sellers = [],
    products = [],
    productsLoading,
    savedProducts,
    onSaveProduct,
    refetchSellers,
    refetch,
    refetchSavedProducts,
    refetchReadProducts,
    pagination,
    onPaginationChange,
    totalPages,
    total,
    readProducts,
  } = useProductFeed() as any;
  const [show, setShow] = useState<boolean>(true);
  const user = getUser();
  const onClick = () => setShow(!show);
  const { toast } = useToast();
  const [markingAsRead, setMarkingAsRead] = useState<boolean>(false);

  const handleRead = () => {
    console.log(selectedSeller);
    setMarkingAsRead(true);
    // TODO: mark all seller as read
    if (selectedSeller === "all") {
      const count = sellers.reduce((acc, seller) => {
        return acc + Number(seller?.totalUnreadProducts || 0);
      }, 0);
      api
        .markAllAsRead(user?.id)
        .then((res) => {
          setMarkingAsRead(false);
          refetchSellers();
          refetchReadProducts();
          refetch();
          toast({
            title: "All sellers products are marked as read.",
            description: `${count} products have been successfully marked.`,
          });
        })
        .catch((err) => {
          setMarkingAsRead(false);
          toast({
            description:
              err?.response?.data?.message ||
              "Something went wrong. Please try again",
            variant: "destructive",
          });
        });
    } else {
      api
        .markAsRead(user?.id, selectedSeller.sellerDbId)
        .then((res) => {
          setMarkingAsRead(false);

          toast({
            title: `${
              selectedSeller?.nickName || selectedSeller?.sellerName
            } products all marked as read.`,
            description: `${selectedSeller?.totalUnreadProducts} products have been successfully marked.`,
          });
          refetchSellers();
          refetchReadProducts();
          refetch();
        })
        .catch((err) => {
          setMarkingAsRead(false);
          toast({
            description:
              err?.response?.data?.message ||
              "Something went wrong. Please try again",
            variant: "destructive",
          });
        });
    }
  };

  useEffect(() => {
    if (!productsLoading) {
      // scroll to top product feed list
      const productFeedList = document.getElementById("product-feed-list");
      if (productFeedList) {
        productFeedList.scrollTo(0, 0);
      }
    }
  }, [productsLoading]);

  console.log("selectedSeller", selectedSeller);
  return (
    <div className="w-full lg:w-auto flex flex-1 h-full flex-col relative justify-between">
      <div className="hidden lg:flex bg-white justify-between items-center pl-4 py-3.5 pr-3 border-b border-b-slate-300 h-[3.25rem]">
        <div className="flex items-center gap-6 w-full">
          <h2 className="font-grotesk text-[1rem]">Feed</h2>
          {selectedSeller && selectedSeller !== "all" && (
            <Button
              variant={"outline"}
              color="gray"
              onClick={onClick}
              className={`px-1.5 h-6 flex gap-1 items-center rounded-[6px] bg-slate-50 border border-slate200`}
            >
              <SellersIcon className="w-3 h-3 [&_path]:stroke-slate-400" />

              <span className="text-slate-400 text-xs font-medium">
                {selectedSeller?.nickName || selectedSeller?.sellerName}
              </span>
            </Button>
          )}
          <Button
            variant={"outline"}
            color="gray"
            onClick={handleRead}
            className={`px-1.5 h-[22px] flex gap-1 items-center rounded-[6px] bg-slate-900 hover:bg-slate-800 border border-slate200 ml-auto [&_svg]:!w-3 [&_svg]:!h-3`}
          >
            <span className="text-white text-xs font-medium">
              Mark all as read
            </span>
            {markingAsRead && <Spinner />}
          </Button>
        </div>
      </div>
      <div
        onClick={onOpenSellerList}
        className="p-2.5 mx-3 mt-3 bg-white rounded-lg shadow border border-slate-200 justify-between items-center flex lg:hidden"
      >
        <div className="flex items-start gap-2">
          <div>
            <div className="flex flex-col">
              {!selectedSeller ? (
                <span className="text-sm font-semibold text-slate-900 leading-5">
                  No Seller Selected
                </span>
              ) : selectedSeller === "all" ? (
                <span className="text-sm font-semibold text-slate-900 leading-5">
                  All Sellers
                </span>
              ) : (
                <>
                  <span className="text-sm font-semibold text-slate-900 leading-5">
                    {selectedSeller?.nickName || selectedSeller?.sellerName}
                  </span>
                  <span className="text-xs font-medium text-slate-600 leading-5">
                    {selectedSeller?.sellerId}
                  </span>
                </>
              )}
            </div>
          </div>
          {selectedSeller?.totalUnreadProducts > 0 && (
            <div className="w-fit rounded-[6px] border border-[rgba(20,83,45,0.50)] bg-slate-900 text-white text-xs font-medium py-[2px] px-1.5 ">
              {selectedSeller?.totalUnreadProducts}
            </div>
          )}
        </div>
        <ChevronDownIcon />
      </div>
      <div className="flex-1 pt-3 pb-0  flex flex-col overflow-auto">
        <FilterView />
        {!productsLoading && sellers?.length > 0 && products?.length > 0 && (
          <div
            id="product-feed-list"
            className="overflow-auto my-[14px] pl-3.5 pr-3 md:pr-[18px]"
          >
            <div className="h-full flex flex-col gap-2">
              {(products || [])?.map((product) => (
                <ProductFeedCard
                  key={product.id}
                  product={product}
                  selectedSeller={selectedSeller}
                  isSaved={!!savedProducts?.find((p) => p === product.id)}
                  isRead={
                    !(
                      product?.isNew &&
                      !readProducts?.find((p) => p === product.id)
                    )
                  }
                  onSaveProduct={() => onSaveProduct(product)}
                  onUpdate={() => {
                    refetch(undefined, undefined, false);
                    refetchSellers();
                    refetchReadProducts();
                  }}
                />
              ))}
            </div>
          </div>
        )}

        {productsLoading && (
          <div className="flex justify-center [&_svg]:text-slate-900 my-12">
            <Spinner />
          </div>
        )}
        {!productsLoading &&
          selectedSeller &&
          sellers?.length > 0 &&
          products.length === 0 && <EmptyProductFeed />}
        {!sellersLoading && (
          <>
            {sellers?.length === 0 && <EmptySellerFeed />}
            {sellers?.length > 0 && !selectedSeller && <EmptyFeed />}
          </>
        )}
      </div>
      {!sellersLoading && sellers?.length > 0 && selectedSeller && (
        <Pagination
          total={total}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          totalPages={totalPages}
          products={products}
          productsLoading={productsLoading}
        />
      )}
    </div>
  );
}
