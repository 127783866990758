import { ReactComponent as TickIcon } from "../../../assets/icons/tick.svg";
import { ReactComponent as TagIcon } from "../../../assets/icons/tag.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { Button } from "../../../@/components/ui/button";
import { useEffect, useMemo, useState } from "react";
import Modal from "../../../components/Modal";
import { Input } from "../../../@/components/ui/input";
import { Chip } from "../../product-feed/components/Chip";
import { api } from "../../../helpers/api";
import { getUser } from "../../../helpers/utils";
import { useTags } from "../context/tags-context";
import { toast } from "../../../@/components/ui/use-toast";
import { FavoriteTag } from "./FavoriteTag";
import Spinner from "../../../components/Spinners";
import { ErrorView } from "../../../components/Error";

const isSame = (tags: string[], tagList: string[]) => {
  if (tags?.length !== tagList?.length) return false;
  return tags?.every((tag) => tagList?.includes(tag));
};

export function TagsModal() {
  const [open, setOpen] = useState(false);
  const { tagList, refetch } = useTags() as any;
  const [saving, setSaving] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [showForm, setShowForm] = useState<boolean>(false);
  const [tag, setTag] = useState<string>("");
  const [tagsToRemove, setTagsToRemove] = useState<any[]>([]);
  const user = getUser();

  const handleAddTag = () => {
    if (tag && tags.length < 25) {
      setTags([...tags, tag]);
      setTag("");
    }
  };

  const undoTag = (tag: string, index: number) => {
    if (!tag) return;
    setTags((prev) => {
      prev.splice(index, 0, tag);
      return [...prev];
    });
    setTagsToRemove(
      tagsToRemove.filter((t) => t.toLowerCase() !== tag.toLowerCase())
    );
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setTag(value);
  };

  const handleTagRemove = (index: number) => {
    const removedTag = tags[index];
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    if (
      removedTag &&
      tagList.find((t) => t.toLowerCase() === removedTag.toLowerCase())
    ) {
      setTagsToRemove([...tagsToRemove, removedTag]);
    }
    toast({
      variant: "light",
      title: `Tag "${removedTag}" has been removed.`,
      description: `Hit "Save" to confirm the change.`,
      action: (
        <Button
          variant="outline"
          onClick={() => undoTag(removedTag, index)}
          className="!text-slate-900 !bg-white text- hover:!bg-slate-50 border border-slate200 !h-8 !px-3 !py-1"
        >
          Undo
        </Button>
      ),
    });
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const response = await api.saveTags(user.id, tags);
      if ([200, 201].includes(response.status)) {
        toast({
          variant: "light",
          title: "New tag(s) has been added.",
          description: `You now have ${tags.length + 1} tags. You can add ${
            25 - (tags.length + 1)
          } more.`,
        });
        await refetch();
      }
      setSaving(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (tagList) {
      setTags(tagList);
    }
  }, [tagList]);

  const disabled = useMemo(() => {
    if (!tag) return true;
    if (tag.length > 28) {
      setErrors((prev) => ({
        ...prev,
        tag: "Exceeding character limit",
      }));
      return true;
    } else if (tags.length >= 25) {
      setErrors((prev) => ({
        ...prev,
        tag: "Maximum of 25 tags allowed",
      }));
      return true;
    } else if (tags.find((t) => t.toLowerCase() === tag.toLowerCase())) {
      //Tag name already exist
      setErrors((prev) => ({
        ...prev,
        tag: "Tag name already exists",
      }));
      return true;
    } else {
      setErrors((prev) => ({
        ...prev,
        tag: "",
      }));
      return false;
    }
  }, [tags, tag]);

  return (
    <div>
      <Button
        variant="ghost"
        className="rounded-[6px] !px-2 md:!px-3 !py-1 flex gap-1.5 items-center !h-8 border border-slate-200 bg-white hover:bg-slate-50 md:bg-transparent md:border-none"
        onClick={() => setOpen(true)}
      >
        <TagIcon />
        <span className="hidden md:block">Manage Tags</span>
      </Button>
      <Modal
        contentClassName="w-[80%] md"
        handleModal={() => setOpen(false)}
        open={open}
        title="Manage Tags"
      >
        <p className="text-slate-500 text-sm leading-[20px] mt-3">
          Easily organize and monitor sellers by tagging them with relevant
          labels. You can up to 25 tags as needed, with each tag limited to 28
          characters.
        </p>
        <div className="grid gap-3 mt-5">
          <label className="text-slate-700 font-medium text-sm">Tags</label>
          <div className="flex py-2 px-3 rounded-[6px] gap-1 items-center flex-wrap border border-slate-200 bg-white shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)]">
            {tags.map((tag, index) => (
              <Chip
                className="!bg-slate-50"
                onClick={() => handleTagRemove(index)}
                key={tag}
              >
                {tag}
              </Chip>
            ))}
            <FavoriteTag />
          </div>
        </div>
        {showForm ? (
          <>
            <div className="flex items-center gap-2 mt-4">
              <Input
                placeholder="Enter tag name"
                value={tag}
                onChange={handleChange}
                className="mb-1"
              />
              <Button
                className="!min-w-8 !h-8 !p-0"
                onClick={handleAddTag}
                disabled={disabled}
              >
                <TickIcon className="w-4 h-4 [&_path]:stroke-white" />
              </Button>
            </div>
            {errors.tag && <ErrorView message={errors.tag} />}
          </>
        ) : (
          <Button
            variant="outline"
            color="gray"
            className="w-full !text-slate-900 mt-4 flex items-center gap-1.5 justify-center"
            onClick={() => setShowForm(true)}
          >
            <PlusIcon className="[&_path]:!stroke-slate-900" />
            Add another
          </Button>
        )}
        <div className="mt-4">
          <Button
            onClick={handleSave}
            disabled={saving || isSame(tags, tagList)}
            className="flex items-center gap-1.5"
          >
            Save {saving && <Spinner />}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
