import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { api } from "../helpers/api";
import { useQuery } from "@tanstack/react-query";
import { getUser } from "../helpers/utils";
import { TagsProvider } from "./sellers/context/tags-context";
import { UserProvider } from "./auth/context/user-context";
import { ProductFeedProvider } from "./product-feed/context/product-feed-context";
import { SavedProductFeedProvider } from "./saved-products/context/saved-product-feed-context";
import { SellerProductProvider } from "./sellers/context/seller-product-context";
import {
  PastDueModal,
  PaymentUpdateSuccessModal,
} from "./settings/PastDueModal";

function MainApp() {
  return (
    <div className="flex-1 flex flex-col lg:flex-row">
      <Sidebar />
      <div className="lg:flex-1 h-[calc(100vh-50px)] lg:h-auto max-h-screen  overflow-auto bg-slate-50 lg:pt-0">
        <Outlet />
      </div>
    </div>
  );
}

function loadScript() {
  let script = document.createElement("script");
  script.src =
    "https://prod-updatemaker-widget.s3.us-east-2.amazonaws.com/updatemaker.min.js";
  script.type = "text/javascript";
  document.head.append(script);
}

export function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const [openPastDueModal, setOpenPastDueModal] = useState(false);
  const [openPaymentUpdateSuccessModal, setOpenPaymentUpdateSuccessModal] =
    useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = getUser();
  const {
    data: activeSubscription,
    isLoading,
    isFetching,
  } = useQuery(["subscription"], () => api.getUserSubscription(user.id));

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", {
        state: { from: `${location.pathname}${location.search}` },
      });
      setIsLoggedIn(false);
    } else {
      loadScript();
      setIsLoggedIn(true);
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
    }
  }, [location]);

  useEffect(() => {
    if (isLoading) return;
    if (!activeSubscription?.[0] && location.pathname !== "/settings") {
      navigate("/subscription");
    } else if (activeSubscription?.[0]?.status === "PastDue") {
      setOpenPastDueModal(true);
    } else if (
      localStorage.getItem("paymentUpdateSuccess") &&
      activeSubscription?.[0]?.status === "Paid"
    ) {
      setOpenPaymentUpdateSuccessModal(true);
      setOpenPastDueModal(false);
      localStorage.removeItem("paymentUpdateSuccess");
    }
  }, [activeSubscription, isLoading, location.pathname, navigate]);

  if (!isLoggedIn) return null;
  if (isLoading) return null;

  return (
    <UserProvider>
      <ProductFeedProvider>
        <SavedProductFeedProvider>
          <SellerProductProvider>
            <TagsProvider>
              <MainApp />
            </TagsProvider>
          </SellerProductProvider>
        </SavedProductFeedProvider>
      </ProductFeedProvider>
      <PastDueModal
        isOpen={openPastDueModal}
        onClose={() => setOpenPastDueModal(false)}
      />
      <PaymentUpdateSuccessModal
        isOpen={openPaymentUpdateSuccessModal}
        onClose={() => setOpenPaymentUpdateSuccessModal(false)}
      />
    </UserProvider>
  );
}
