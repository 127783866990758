import { Button } from "../../../@/components/ui/button";
import { SearchInput } from "../../../components/Search";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { ProductFeedCard } from "../../product-feed/components/ProductFeedCard/ProductFeedCard";
import { getUser } from "../../../helpers/utils";
import { SellerProductFilterView } from "./components/SellerProductFilterView";
import { useEffect, useRef } from "react";
import Spinner from "../../../components/Spinners";
import { EmptyFeed } from "../../product-feed/Feed/components/EmptyFeed";

export function Products({
  products = [],
  seller,
  isLoading,
  loadMore,
  total,
  savedProducts,
  onSaveProduct,
  readProducts,
  refetchReadProducts,
}) {
  const user = getUser();

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          products &&
          products.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, products, total, isLoading]);

  return (
    <div className="mt-[25px]">
      <div className="flex gap-2.5">
        <h2 className="font-grotesk text-lg font-medium text-black">
          Products
        </h2>
        <div className="bg-slate-50 flex flex-col self-center justify-center w-fit text-xs font-medium leading-[18px] rounded-[6px] border border-slate-200 px-2 py-[1px] h-[22px]">
          {total}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between gap-3 my-4 [&_.search-input]:!h-8 [&_.search-input]:!pl-3">
        <SellerProductFilterView />
      </div>
      <div className="flex flex-col gap-4 h-[calc(98vh-390px)] lg:h-[calc(98vh-360px)] overflow-auto">
        {!isLoading && products?.length === 0 && (
          <EmptyFeed showAddSeller={false} />
        )}
        {(products || [])?.map((product: any) => (
          <ProductFeedCard
            key={product.id}
            product={product}
            selectedSeller={seller}
            isSaved={!!savedProducts?.find((p) => p === product.id)}
            onSaveProduct={() => onSaveProduct(product)}
            isRead={
              !(product?.isNew && !readProducts?.find((p) => p === product.id))
            }
            onUpdate={() => {
              refetchReadProducts();
            }}
          />
        ))}
        {isLoading && (
          <div className="flex justify-center [&_svg]:text-slate-900">
            <Spinner />
          </div>
        )}

        <div ref={observerTarget}></div>
        <div className="p-5"></div>
      </div>
    </div>
  );
}
