export const GOOGLE_OAUTH_CLIENT_ID =
  "517802682568-s26tenb76v6cnu66t3hq6v8r0vfh3the.apps.googleusercontent.com";

export const DEFAULT_SUBSCRIPTION_SELLER = 20;

export const subscriptionList = [
  {
    tier: "ST",
    sellers: 20,
    price: 35,
    title: "Standard",
    desc: "Made for starters.",
    bg: "bg-[#D5EFFA]",
    text: "text-[#139EA7]",
  },
  {
    tier: "PR",
    sellers: 40,
    price: 70,
    title: "Pro",
    desc: "Created for growing starters.",
    bg: "bg-[#DECAFF]",
    text: "text-[#883EC2]",
  },
  {
    tier: "MX",
    sellers: 80,
    price: 130,
    title: "Max",
    desc: "The plan for everyone.",
    bg: "bg-[#FFE5D6]",
    text: "text-[#AA6413]",
  },
  {
    tier: "UL",
    sellers: 200,
    price: 230,
    title: "Ultra",
    desc: "The plan you’ve always needed.",
    bg: "!bg-slate-900",
    text: "text-gradient",
    gradient: true,
  },
];
