import { DropdownMenu, Button } from "@radix-ui/themes";
import { ReactComponent as CaretSortIcon } from "../../../../assets/icons/caret-sort.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../assets/icons/check-mark.svg";
import { sortingOptions } from "../constant";

function DropDownItem({ text, value, onClick, selected }) {
  // F1F5F9
  const handleClick = () => {
    onClick(value);
  };
  return (
    <DropdownMenu.Item
      onClick={handleClick}
      className="hover:!text-slate700 hover:!bg-[#F1F5F9] !rounded-[6px] !cursor-pointer"
    >
      <div className="w-4 h-4">
        {selected && (
          <CheckMarkIcon className="w-4 h-4 mr-2 [&>path]:!stroke-slate700" />
        )}
      </div>
      {text}
    </DropdownMenu.Item>
  );
}

export function SortingDropdown({ onChange, value }) {
  const handleClick = (action: string) => {
    onChange(action);
  };
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button
          variant="outline"
          color="gray"
          className="!text-[#0F172A] !w-[75px] !shadow-[inset_0_0_0_1px_#E2E8F0] !font-medium !text-sm !rounded-[6px] flex items-center !gap-1.5 !px-3 md:!py-1 !h-[32px] !font-inter"
        >
          Sort
          <CaretSortIcon className="[&_path]:stroke-slate-400" />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="[&_.rt-BaseMenuViewport]:!p-[5px]">
        {sortingOptions.map((option) => (
          <DropDownItem
            key={option.value}
            text={option.text}
            value={option.value}
            onClick={handleClick}
            selected={value === option.value}
          />
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
