import clsx from "clsx";
import { ReactComponent as CheckIcon } from "../../assets/icons/li.svg";
import { getUser } from "../../helpers/utils";
import { api } from "../../helpers/api";
import CustomButton from "../../components/Button";
import { useQuery } from "@tanstack/react-query";
import useStripeSession from "../../helpers/hooks/useSubscription";
import { subscriptionList } from "../../constant";

function Subscription() {
  return (
    <div className="max-w-[900px] mx-auto lg:h-full grid content-center p-4">
      <div className="grid gap-3 text-center mb-[2.8125rem]">
        <h1 className="text-[1.75rem]">Choose a plan</h1>
        <p className="max-w-[500px] justify-self-center">
          All our plans are designed to fit every stage of your journey with
          unlimited access to all features
        </p>
      </div>
      <div className="flex gap-[1.25rem] w-full flex-wrap">
        <DescriptionCard />
        <div className="whitespace-nowrap flex gap-[1.25rem] lg:w-2/3 flex-wrap w-full md:flex-row flex-col">
          {subscriptionList.map((plan) => (
            <PlanCard
              title={plan.title}
              className={`${plan.bg} ${plan.text}`}
              desc={plan.desc}
              sellers={plan.sellers}
              price={plan.price}
              type={plan.tier as "ST" | "MX" | "PR" | "UL"}
              key={plan.tier}
              gradient={plan?.gradient}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function PlanCard({
  title,
  className,
  gradient,
  desc,
  sellers,
  price,
  type,
}: {
  title: string;
  className?: string;
  gradient?: boolean;
  desc: string;
  sellers: number;
  price: number;
  type: "ST" | "MX" | "PR" | "UL";
}) {
  const { handleGetStripeSession, stripeLoading, isLoading } =
    useStripeSession();

  if (isLoading) return null;

  return (
    <div className="!md:w-[50%] !w-[100%] border-solid border-[1px] border-slate-200 bg-white rounded-[12px] p-[1.25rem] flex-1 !shadow-customCard gap-[0.875rem] flex flex-col">
      <div className="relative w-min">
        {gradient && (
          // have a black background behind the gradient
          <div className="absolute inset-0 bg-slate-900 rounded-[6px] z-0"></div>
        )}
        <div
          className={clsx(
            "font-grotesk px-[0.625rem] py-[3px] w-fit rounded-[6px] relative",
            className
          )}
        >
          <span className="z-1">{title}</span>
        </div>
      </div>
      <div className="grid gap-[0.625rem] pb-[0.875rem]">
        <h2 className="font-grotesk text-[1.25rem]">{sellers} sellers</h2>
        <h3 className="text-[19px]">${price}/m</h3>
      </div>
      {/* <p className="text-slate-600 text-[0.875rem]">{desc}</p> */}
      <CustomButton
        onClick={() => {
          handleGetStripeSession({ type });
        }}
        loading={stripeLoading}
      >
        Upgrade to {title}
      </CustomButton>
    </div>
  );
}

function DescriptionCard() {
  return (
    <div className="border-solid border-[1px] border-slate-200 bg-white rounded-[12px] p-[1.25rem] flex-1 !shadow-customCard min-w-1/3">
      <h2 className="font-grotesk text-[1.25rem] mb-2.5">Features For You</h2>
      <p className="text-slate-600 text-[0.875rem]">
        Say goodbye to chaos and hello to streamlined reverse sourcing.
      </p>
      <ul className="mt-5 font-[400] text-[0.875rem] gap-3 grid">
        <li className="flex gap-[0.625rem]">
          <CheckIcon /> Seller Management
        </li>
        <li className="flex gap-[0.625rem]">
          <CheckIcon />
          Bookmark & Nickname Sellers
        </li>
        <li className="flex gap-[0.625rem]">
          <CheckIcon />
          All In One Product Feed
        </li>
        <li className="flex gap-[0.625rem]">
          <CheckIcon />
          Pre-populated Product Feeds
        </li>
        <li className="flex gap-[0.625rem]">
          <CheckIcon />
          Bookmark Products
        </li>
        <li className="flex gap-[0.625rem]">
          <CheckIcon />
          Saved Product Feed
        </li>
        <li className="flex gap-[0.625rem]">
          <CheckIcon />
          Pause/Activate Sellers
        </li>
        <li className="flex gap-[0.625rem]">
          <CheckIcon />
          Tags Sellers with Keywords
        </li>
        <li className="flex gap-[0.625rem]">
          <CheckIcon />
          Filter Options
        </li>
        <li className="flex gap-[0.625rem]">
          <CheckIcon />
          Keepa Chart Integration
        </li>
      </ul>
    </div>
  );
}

export default Subscription;
