import { Button } from "../../@/components/ui/button";
import { getUser } from "../../helpers/utils";
import { api } from "../../helpers/api";
import CustomButton from "../../components/Button";
import Modal from "../../components/Modal";
import FormField from "../../components/FormField";
import { Form, Formik } from "formik";
import { Input } from "../../@/components/ui/input";

import * as Yup from "yup";

export default function EditProfileModal({
  open,
  onClose,
  onChangeEmail,
}: {
  open: boolean;
  onClose: () => void;
  onChangeEmail: () => void;
}) {
  const user = getUser();

  return (
    <Modal open={open} title="Edit profile" handleModal={onClose}>
      <Formik
        initialValues={{
          fullName: user.fullName,
        }}
        isInitialValid={false}
        onSubmit={(values, actions) => {
          api
            .putUserProfile(user.id, values)
            .then(() => {
              onClose();
              actions.setSubmitting(false);
            })
            .catch((err) => {
              console.log(err);
              actions.setSubmitting(false);
            });
        }}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().required("Full name is required"),
        })}
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => (
          <Form className="mt-5 grid gap-4">
            <FormField
              label="Your name"
              type="text"
              id="fullName"
              name="fullName"
              placeholder="Enter your full name"
            />
            <div className="grid gap-[0.375rem]">
              <label className="text-[#030712] text-[0.875rem] font-[Inter] justify-between flex w-full font-[500]">
                Email
              </label>
              <div className="relative">
                <Input
                  disabled
                  // id="email"
                  // name="email"
                  value={user.email}
                  placeholder="Enter your email"
                  onChange={(e) => setFieldValue("email", e.target.value)}
                />

                <Button
                  variant="link"
                  className="p-0 m-0 absolute top-1/2 right-3 transform -translate-y-1/2 text-primary-500 underline text-slate-900 text-[0.8125rem] font-[400]"
                  onClick={() => {
                    onClose();
                    onChangeEmail();
                  }}
                  type="button"
                >
                  Change email
                </Button>
              </div>
            </div>
            <CustomButton
              type="submit"
              className="w-fit ml-auto"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
            >
              Save changes
            </CustomButton>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
