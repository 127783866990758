import { useEffect, useState } from "react";
import { SellerList } from "./SellerList/SellerList";
import { Feed } from "./Feed/Feed";
import Modal from "../../components/Modal";
import clsx from "clsx";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../@/components/ui/select";
import CustomButton from "../../components/Button";
import { getUser } from "../../helpers/utils";
import { api } from "../../helpers/api";
import { useToast } from "../../@/components/ui/use-toast";
import { useQuery } from "@tanstack/react-query";
import useStripeSession from "../../helpers/hooks/useSubscription";
import { cn } from "../../@/lib/utils";
import { useProductFeed } from "./context/product-feed-context";
import { countries } from "../../helpers/consts";
import { MobileSellerList } from "./SellerList/components/MobileDrawer";
import useResponsive from "../../helpers/useResponsive";
import IntercomComponent from "../../components/Intercom";
import { SellerProductsFoundModal } from "./SellerList/components/SellerProductsFoundModal";

function ProductFeed() {
  const { isSmallDevice } = useResponsive();
  const [openMobileSellerList, setOpenMobileSellerList] =
    useState<boolean>(false);
  const [onboardingModal, setOnboardingModal] = useState<boolean>(false);
  const {
    selectedSeller,
    sellers,
    refetchSavedProducts,
    refetchReadProducts,
    setSelectedSeller,
    productsLoading,
  } = useProductFeed() as any;
  const user = getUser();
  const { data, isLoading } = useQuery(["user"], () =>
    api.getUser(getUser().id)
  );
  const [openProductFoundModal, setOpenProductFoundModal] =
    useState<any>(false);

  const handleOnboardingModal = () => {
    setOnboardingModal(!onboardingModal);
  };

  const handleOpenSellerList = () => {
    setOpenMobileSellerList(!openMobileSellerList);
  };

  useEffect(() => {
    if (selectedSeller) {
      setOpenMobileSellerList(false);
    }
  }, [selectedSeller]);

  useEffect(() => {
    refetchSavedProducts();
    refetchReadProducts();
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      (!data?.experience ||
        !data?.country ||
        data.experience === 0 ||
        data.country === "" ||
        data.country.length === 0)
    ) {
      setOnboardingModal(true);
    } else {
      setOnboardingModal(false);
    }
  }, [data]);

  useEffect(() => {
    if (!isSmallDevice) {
      setOpenMobileSellerList(false);
    }
  }, [isSmallDevice]);

  if (isLoading) return null;

  return (
    <IntercomComponent customPadding={64}>
      {onboardingModal && (
        <OnboardingModal
          onboardingModal={onboardingModal}
          handleOnBoardingModal={handleOnboardingModal}
        />
      )}
      <div className="hidden lg:flex items-start h-full">
        <SellerList setOpenProductFoundModal={setOpenProductFoundModal} />
        <Feed />
      </div>
      <div className="flex lg:hidden flex-col items-start h-full">
        {openMobileSellerList && (
          <MobileSellerList
            open={openMobileSellerList}
            onClose={() => {
              console.log("dddd");
              setOpenMobileSellerList(false);
            }}
            setOpenProductFoundModal={(data) => {
              setOpenMobileSellerList(false);
              setOpenProductFoundModal(data);
            }}
          />
        )}
        {(selectedSeller || sellers?.length === 0) && (
          <Feed onOpenSellerList={handleOpenSellerList} />
        )}
      </div>

      <SellerProductsFoundModal
        open={!!openProductFoundModal}
        setOpen={setOpenProductFoundModal}
        seller={{
          ...openProductFoundModal?.seller,
        }}
        onExplore={() => {
          console.log("Explore");
          const seller = {
            ...openProductFoundModal,
            ...openProductFoundModal?.seller,
            sellerDbId: openProductFoundModal?.seller?.id,
          };

          console.log("Selected seller111", seller, sellers);
          const sellerExists = sellers.find(
            (s) => s.sellerDbId === seller.sellerDbId
          );
          !productsLoading && setSelectedSeller(sellerExists || seller);
        }}
      />
    </IntercomComponent>
  );
}

function OnboardingModal({
  onboardingModal,
  handleOnBoardingModal,
}: {
  onboardingModal: boolean;
  handleOnBoardingModal: () => void;
}) {
  const [form, setForm] = useState({
    country: "",
    experience: "",
  });
  const user = getUser();
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleSubmit = () => {
    setIsLoading(true);

    api
      .putUserProfile(user.id, form)
      .then(async (res) => {
        toast({
          description: "Account setup successful",
        });
        setIsLoading(false);
        handleOnBoardingModal();
        // parse user in local storage to update the experience and country
        const user = getUser();
        user.experience = form.experience;
        user.country = form.country;
        localStorage.setItem("user", JSON.stringify(user));
      })
      .catch(() => {
        toast({
          description: "Account setup failed",
          variant: "destructive",
        });
        setIsLoading(false);
      });
  };

  return (
    <Modal noClose open={onboardingModal} title="Welcome to Stealth Seller!">
      <p className="text-slate-600 my-3">
        We are excited to have you join our community of sellers. Let's get
        started.
      </p>

      <SubscriptionTitle />
      <div className="grid gap-3 mt-5">
        <h2 className="font-grotesk text-[0.875rem] font-[500] text-black">
          Set up Your Account
        </h2>
        <p>
          Please specify the Amazon marketplace where you conduct your sales and
          your experience!
        </p>
        <div className="grid gap-4">
          <label className="grid gap-2">
            Country
            <Select
              onValueChange={(value) => {
                setForm({
                  ...form,
                  country: value,
                });
              }}
            >
              <SelectTrigger className="w-full text-black">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup className="max-h-[200px]">
                  {/* <SelectLabel>Fruits</SelectLabel> */}
                  {countries.map((country) => (
                    <SelectItem key={country.code} value={country.code}>
                      {country.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </label>
          <label className="grid gap-2">
            Experience
            <Select
              onValueChange={(value) => {
                setForm({
                  ...form,
                  experience: value,
                });
              }}
            >
              <SelectTrigger className="w-full text-black">
                <SelectValue placeholder="Select your level of experience" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup className="max-h-[200px]">
                  {/* <SelectLabel>Fruits</SelectLabel> */}
                  <SelectItem value="1">Less than 1 year</SelectItem>
                  <SelectItem value="2">1-2 years of experience</SelectItem>
                  <SelectItem value="3">3-4 years of experience</SelectItem>
                  <SelectItem value="4">5+ years of experience</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </label>
          <CustomButton
            onClick={handleSubmit}
            disabled={!form.country || !form.experience}
            loading={isLoading}
          >
            Done
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
}

function SubscriptionTitle() {
  const { activeSubscription } = useStripeSession();

  return (
    <div className="relative text-center">
      {activeSubscription?.[0]?.tier === "UL" && (
        // have a black background behind the gradient
        <div className="absolute inset-0 bg-slate-900 rounded-[6px] z-0"></div>
      )}
      <div
        className={clsx(
          "font-grotesk relative px-[0.625rem] py-[0.375rem] rounded-[6px] relative text-center w-full",
          cn(
            activeSubscription?.[0]?.tier === "ST" &&
              "bg-[#D5EFFA] text-[#139EA7]",
            activeSubscription?.[0]?.tier === "PR" &&
              "bg-[#DECAFF] text-[#883EC2]",
            activeSubscription?.[0]?.tier === "MX" &&
              "bg-[#FFE5D6] text-[#AA6413]",
            activeSubscription?.[0]?.tier === "UL" && "text-gradient"
          )
        )}
      >
        <div className="w-fit mx-auto">
          <div
            className={cn(
              "w-fit",
              activeSubscription?.[0]?.tier === "UL" && "text-gradient"
            )}
          >
            <span className="z-1 md:text-[1rem] text-[0.75rem]">
              {activeSubscription?.[0]?.tier === "ST"
                ? "Standard"
                : activeSubscription?.[0]?.tier === "PR"
                ? "Pro"
                : activeSubscription?.[0]?.tier === "MX"
                ? "Max"
                : activeSubscription?.[0]?.tier === "UL"
                ? "Ultra"
                : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductFeed;
