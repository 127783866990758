import React, { useEffect, useState, useCallback } from "react";
import { api } from "../../../helpers/api";
import { getUser } from "../../../helpers/utils";
import { useQuery } from "@tanstack/react-query";
import useProducts from "./useProducts";
import { NumberFilter } from "../SellerList/constant";
import moment from "moment";
import { toast } from "../../../@/components/ui/use-toast";

export const ProductFeedContext = React.createContext({});

export function ProductFeedProvider({ children }) {
  const [selectedSeller, setSelectedSeller] = useState<any>(null);
  const [appliedFilter, setAppliedFilter] = useState<any>({});
  const [productFilter, setProductFilter] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const user = getUser();
  const {
    filters,
    updateFilters,
    setSellerId,
    onSearch,
    state,
    pagination,
    onPaginationChange,
    totalPages,
    products,
    isLoading,
    total,
    refetch,
  } = useProducts();

  const {
    data: sellers,
    isLoading: sellersLoading,
    refetch: refetchSellers,
  } = useQuery(["sellers"], () => api.getSellers(user.id), {
    enabled: !!user.id,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const {
    data: savedProducts,
    isLoading: savedProductsLoading,
    refetch: refetchSavedProducts,
  } = useQuery(
    ["savedProducts", user.id],
    () => api.getSavedProductIds(user.id),
    {
      enabled: !!user.id,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  const {
    data: readProducts,
    isLoading: readProductsLoading,
    refetch: refetchReadProducts,
  } = useQuery(
    ["readProducts", user.id],
    () => api.fetchUserReadProducts(user.id),
    {
      enabled: !!user.id,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  const onApply = useCallback(
    (filter) => {
      // apply filter
      setAppliedFilter((prev) => ({ ...prev, [filter.name]: filter }));
      if (filter.name === "categories") {
        updateFilters({
          categories: filter.value,
        });
        return;
      }
      if (filter.name === "newlyPosted") {
        updateFilters({
          newlyPosted: filter.value,
        });
        return;
      }
      if (filter.name === "amazonListing") {
        updateFilters({
          amazonListing: filter.value,
        });
        return;
      }
      if (filter.name === "storefrontFulfillmentMethod") {
        updateFilters({
          storefrontFulfillmentMethod: filter.value,
        });
        return;
      }
      if (filter.name === "timePosted") {
        updateFilters({
          minTimePosted: filter.startDate
            ? moment(filter.startDate).format("YYYY-MM-DD")
            : filter.startDate,
          maxTimePosted: filter.endDate
            ? moment(filter.endDate).format("YYYY-MM-DD")
            : filter.endDate,
        });
        return;
      }
      // evaluate filter & set to useProducts
      // filter: { name: "", condition: "grater_than/less_than/between", fromValue: "100", toValue: "200" }
      const minField = `min${filter.name}`;
      const maxField = `max${filter.name}`;
      if (filter.condition === NumberFilter.BETWEEN) {
        updateFilters({
          [minField]: filter.fromValue,
          [maxField]: filter.toValue,
        });
      } else if (filter.condition === NumberFilter.GRATER_THAN) {
        updateFilters({
          [minField]: filter.fromValue,
        });
      } else if (filter.condition === NumberFilter.LESS_THAN) {
        updateFilters({
          [minField]: 0,
          [maxField]: filter.fromValue,
        });
      }
    },
    [updateFilters]
  );

  const onClearFilter = useCallback(
    (filterName) => {
      if (filterName === "categories") {
        updateFilters({
          categories: undefined,
        });

        setAppliedFilter((prev) => {
          delete prev[filterName];
          return { ...prev };
        });
        return;
      }

      if (
        [
          "amazonListing",
          "newlyPosted",
          "storefrontFulfillmentMethod",
        ].includes(filterName)
      ) {
        updateFilters({
          [filterName]: undefined,
        });
        setAppliedFilter((prev) => {
          delete prev[filterName];
          return { ...prev };
        });
        return;
      }
      if (filterName === "timePosted") {
        updateFilters({
          minTimePosted: undefined,
          maxTimePosted: undefined,
        });
        setAppliedFilter((prev) => {
          delete prev[filterName];
          return { ...prev };
        });
        return;
      }
      const minField = `min${filterName}`;
      const maxField = `max${filterName}`;
      updateFilters({
        [minField]: undefined,
        [maxField]: undefined,
      });
      setAppliedFilter((prev) => {
        delete prev[filterName];
        return { ...prev };
      });
    },
    [updateFilters]
  );

  const onClearAllFilters = useCallback(() => {
    updateFilters({}, true);
    setAppliedFilter({});
  }, [updateFilters]);

  const onSaveProduct = async (product: any) => {
    try {
      const isSaved = savedProducts?.find((p) => p === product.id);
      const API = isSaved ? api.removeSavedProduct : api.saveProduct;
      const response = await API(user.id, product.id);
      if ([200, 201].includes(response.status)) {
        await refetchSavedProducts();
        toast({
          description: (
            <div className="items-center gap-3 flex">
              <div className="w-[46px] h-[46px] px-0.5 justify-center items-center flex">
                <img
                  className="w-full h-full object-cover "
                  src={product?.images[0]}
                />
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch text-slate-900 text-sm font-semibold font-['Inter'] leading-tight">
                  {isSaved
                    ? "Product has been unbookmarked."
                    : "Product has been bookmarked"}
                </div>
                <div className="max-w-[275px] overflow-hidden whitespace-nowrap text-ellipsis opacity-90 text-slate-900 text-sm font-normal leading-tight">
                  {product?.title}
                </div>
              </div>
            </div>
          ),
        });
      }
    } catch (e) {
      console.log(e);
      toast({
        title: "Something went wrong, please try again later.",
      });
    }
  };

  useEffect(() => {
    setSelectedSeller((selectedSeller) => {
      if (sellers?.length > 0 && !selectedSeller) {
        return sellers[0];
      } else if (sellers?.length > 0 && selectedSeller) {
        const updatedSeller = sellers?.find((s) => s.id === selectedSeller.id);
        if (updatedSeller) {
          return { ...selectedSeller, ...updatedSeller };
        }
        return selectedSeller;
      } else if (sellers?.length === 0) {
        refetch();
        return null;
      }
    });
  }, [sellers]);

  useEffect(() => {
    if (selectedSeller) {
      setSellerId(selectedSeller === "all" ? "all" : selectedSeller.sellerDbId);
    }
  }, [selectedSeller]);

  console.log(savedProducts);

  const values = {
    sellers,
    sellersLoading,
    selectedSeller,
    setSelectedSeller,
    loading,
    setLoading,
    productFilter,
    setProductFilter,
    onApply,
    onSearch,
    searchState: state,
    onClearFilter,
    onClearAllFilters,
    products: products || [],
    productsLoading: isLoading,
    pagination,
    onPaginationChange,
    totalPages,
    total,
    refetchSellers,
    savedProducts,
    onSaveProduct,
    refetch,
    appliedFilter,
    setAppliedFilter,
    refetchSavedProducts,
    readProducts,
    readProductsLoading,
    refetchReadProducts,
  };

  return (
    <ProductFeedContext.Provider value={values}>
      {children}
    </ProductFeedContext.Provider>
  );
}

export function useProductFeed() {
  const context = React.useContext(ProductFeedContext);
  if (context === undefined) {
    throw new Error("useTags must be used within a ProductFeedProvider");
  }
  return context;
}
