import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./auth/login/Login";
import Register from "./auth/register/Register";
import { Main } from "./main";
import ResetPassword from "./auth/reset-password/ResetPassword";
import ForgotPassword from "./auth/forgot-password/ForgotPassword";
import ProductFeed from "./product-feed/ProductFeed";
import Subscription from "./subscription/Subscription";
import Settings from "./settings/Settings";
import Sellers from "./sellers/Sellers";
import SavedProductFeed from "./saved-products/SavedProducts";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="register" element={<Register />} />
      </Route>
      <Route element={<Main />}>
        <Route element={<ProductFeed />} path="dashboard" />
        <Route element={<Subscription />} path="subscription" />
        <Route element={<SavedProductFeed />} path="saved-products" />
        <Route element={<Settings />} path="settings" />
        <Route element={<Sellers />} path="sellers" />
        <Route
          index
          element={
            // if loggged in redirect to dashboard
            <Navigate to="/login" />
          }
        />
      </Route>
    </Routes>
  );
};
export default RoutesList;
