import { ReactComponent as BookmarkIcon } from "../../../../assets/icons/bookmark2.svg";
import { ReactComponent as BookmarkFilledIcon } from "../../../../assets/icons/bookmark-filled.svg";
import { Button } from "../../../../@/components/ui/button";
import { Tooltip } from "@radix-ui/themes";

export function BookmarkButton({
  className,
  bookmarked,
  onClick,
  tooltip,
}: {
  className?: string;
  bookmarked: boolean;
  onClick: (e: any) => void;
  tooltip?: string;
}) {
  if (tooltip) {
    return (
      <Button
        variant={"outline"}
        color="gray"
        onClick={onClick}
        className={`!p-0 !h-fit rounded-[6px] !border-none`}
      >
        <Tooltip content={tooltip}>
          <div
            className={`p-1 flex items-center justify-center rounded-[6px] border border-slate200 w-6 h-6`}
          >
            {bookmarked ? (
              <BookmarkFilledIcon className="bookmark-filled-icon" />
            ) : (
              <BookmarkIcon className="bookmark-icon" />
            )}
          </div>
        </Tooltip>
      </Button>
    );
  }

  return (
    <Button
      variant={"outline"}
      color="gray"
      onClick={onClick}
      className={`p-1 rounded-[6px] border border-slate200 w-6 h-6`}
    >
      {bookmarked ? (
        <BookmarkFilledIcon className="bookmark-filled-icon" />
      ) : (
        <BookmarkIcon className="bookmark-icon" />
      )}
    </Button>
  );
}
