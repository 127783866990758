import { useToast } from "../../@/components/ui/use-toast";
import { getUser } from "../../helpers/utils";
import { api } from "../../helpers/api";
import CustomButton from "../../components/Button";
import Modal from "../../components/Modal";
import FormField from "../../components/FormField";
import { Form, Formik } from "formik";
import { useState } from "react";
import ConfirmIcon from "../../assets/icons/check.svg";
import * as Yup from "yup";

const passwordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/g,
      "Password must contain at least one uppercase and one lowercase letter and one special character"
    ),
  newPassword: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/g,
      "Password must contain at least one uppercase and one lowercase letter and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

export default function ChangePasswordModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [success, setSuccess] = useState(false);
  const { toast } = useToast();

  return (
    <Modal
      open={open}
      title={success ? "" : "Change password"}
      handleModal={onClose}
      noClose={success}
    >
      {success && (
        <div className="grid">
          <img src={ConfirmIcon} alt="confirm" className="mx-auto" />
          <div className="grid gap-2 text-center mb-5 mt-6">
            <span className="font-grotesk font-[500] text-[1.25rem] text-slate-900">
              Password changed!
            </span>
            <span>Your password has been changed successfully!</span>
          </div>
          <CustomButton
            onClick={() => {
              onClose();
              setSuccess(false);
            }}
            className="w-fit mx-auto"
          >
            Awesome!
          </CustomButton>
        </div>
      )}
      {!success && (
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          isInitialValid={false}
          validationSchema={passwordValidationSchema}
          onSubmit={(values, actions) => {
            api
              .patchPassword(getUser().id, values)
              .then(() => {
                actions.setSubmitting(false);
                setSuccess(true);
              })
              .catch((err) => {
                console.log(err);
                toast({
                  description:
                    err?.response?.data?.message ||
                    "Failed to change password. Please try again.",
                  variant: "destructive",
                });
                actions.setSubmitting(false);
                setSuccess(false);
              });
          }}
        >
          {({ isValid, isSubmitting }) => (
            <Form className="mt-5 grid gap-4">
              <FormField
                label="Current password"
                type="password"
                id="oldPassword"
                name="oldPassword"
                placeholder="Enter your current password"
              />
              <FormField
                label="New password"
                type="password"
                id="newPassword"
                name="newPassword"
                placeholder="Enter your new password"
              />
              <FormField
                label="Confirm password"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm your new password"
              />
              <CustomButton
                type="submit"
                className="w-fit ml-auto"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              >
                Change password
              </CustomButton>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
