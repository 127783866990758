import { Button } from "../../../@/components/ui/button";
import { ReactComponent as ShowIcon } from "../../../assets/icons/eye.svg";
import { ReactComponent as HideIcon } from "../../../assets/icons/eye-off.svg";
import { useMemo, useState } from "react";
import { SearchInput } from "../../../components/Search";
import { SortingDropdown } from "./components/SortingDropdown";
import { SellerCard } from "./components/SellerCard";
import { filteredSellers } from "../../sellers/context/utils";
import { useProductFeed } from "../context/product-feed-context";
import { EmptySeller } from "../../sellers/components/EmptySeller";
import { useNavigate } from "react-router-dom";
import { SellerDetails } from "../../sellers/SellerDetails/SellerDetails";
import { DeleteSellerConfirm } from "../../sellers/components/DeleteSellerConfirm";
import { useUser } from "../../auth/context/user-context";
import {
  DEFAULT_SUBSCRIPTION_SELLER,
  subscriptionList,
} from "../../../constant";
import { favoriteTagInfo } from "../../sellers/components/FavoriteTag";
import { api } from "../../../helpers/api";
import { SellerFormModal } from "../../sellers/components/SellerFormModal";
import { getUser } from "../../../helpers/utils";
import { AllSellerCard } from "./components/AllSellerCard";
import { AddSellerPopup } from "./components/AddSellerPopup";

const sortSellers = (list, sorting) => {
  if (sorting === "active") {
    return list.sort((a, b) => {
      if (a.status === "A") {
        return -1;
      } else {
        return 1;
      }
    });
  }
  if (sorting === "paused") {
    return list.sort((a, b) => {
      if (a.status === "P") {
        return -1;
      } else {
        return 1;
      }
    });
  }
  if (sorting === "bookmarked") {
    return list.sort((a, b) => {
      if (a.tags.includes(favoriteTagInfo.name)) {
        return -1;
      } else {
        return 1;
      }
    });
  }
  if (sorting === "most_saved") {
    return list.sort((a, b) => {
      if ((a?.totalSavedProducts || 0) > (b?.totalSavedProducts || 0)) {
        return -1;
      } else {
        return 1;
      }
    });
  }
  if (sorting === "new_products") {
    return list.sort((a, b) => {
      if ((a?.totalUnreadProducts || 0) > (b?.totalUnreadProducts || 0)) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  return list;
};

export function SellerList({ setOpenProductFoundModal }) {
  const { sellersLoading, sellers, refetchSellers, refetch, productsLoading } =
    useProductFeed() as any;
  const user = getUser();
  const navigate = useNavigate();
  const { selectedSeller, setSelectedSeller } = useProductFeed() as any;
  const [sorting, setSorting] = useState<string>("");
  const [showBookmarkedSellersOnly, setShowBookmarkedSellersOnly] =
    useState(false);
  const [search, setSearch] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [userView, setUserView] = useState<any>(null);
  const [removeConfirm, setRemoveConfirm] = useState<any>(null);
  const [isEditInfoOpen, setIsEditInfoOpen] = useState<boolean>(false);

  const {
    activeSubscription,
    totalActiveUsers,
    refetchSellers: fetchSellers,
  } = useUser() as any;
  const onClick = () => setShow(!show);
  const handleReadAll = () => {
    console.log("Mark all as read");
    const sellerIds = sellers
      .filter((seller) => seller?.totalUnreadProducts > 0)
      .map((seller) => seller.sellerDbId);
    // promise.all call to mark all as read
    // api.markAsRead(sellerIds);
    const promises = sellerIds.map((sellerId) =>
      api.markAsRead(user?.id, sellerId)
    );
    Promise.all(promises).then(() => {
      refetchSellers();
      fetchSellers();
      refetch();
    });
  };
  const handleAddSeller = () => {
    navigate("/sellers");
  };

  const onSubscription = () => {
    navigate("/subscription");
  };

  const subscriptionTier = activeSubscription?.[0]?.tier;
  const subscriptionInfo = subscriptionList.find(
    (sub) => sub.tier === subscriptionTier
  );
  const isActiveSellerReached =
    totalActiveUsers >=
    (subscriptionInfo?.sellers || DEFAULT_SUBSCRIPTION_SELLER);

  console.log(showBookmarkedSellersOnly, "list");

  const filteredSellerList = useMemo(() => {
    return sortSellers(
      filteredSellers(sellers, search, undefined, showBookmarkedSellersOnly),
      sorting
    );
  }, [sellers, search, showBookmarkedSellersOnly, sorting]);

  return (
    <div className="w-full h-full lg:w-[282px] bg-white border-r border-r-slate-300">
      <div className="flex justify-between items-center pl-4 py-3.5 pr-3 border-b border-b-slate-300 h-[3.25rem]">
        <div className="flex items-center gap-2">
          <h2 className="font-grotesk text-[1rem]">Sellers</h2>
        </div>

        <Button
          variant={"outline"}
          color="gray"
          onClick={onClick}
          className={`px-1.5 h-6 flex gap-1 items-center rounded-[6px] hover:bg-slate-50 border border-slate200`}
        >
          <span className="text-slate900 text-xs font-medium">Tags</span>
          {show ? (
            <ShowIcon className="w-3 h-3" />
          ) : (
            <HideIcon className="w-3 h-3" />
          )}
        </Button>
      </div>
      <div>
        <div className="pt-3.5 pb-2.5 px-3.5 flex justify-between items-center gap-2">
          <AddSellerPopup
            totalActiveUsers={totalActiveUsers}
            sellerLimit={
              subscriptionInfo?.sellers || DEFAULT_SUBSCRIPTION_SELLER
            }
            onSuccess={() => {
              refetchSellers();
              fetchSellers();
            }}
            onSelect={(seller) => {
              console.log("Selected seller111", seller, sellers);
              const sellerExists = sellers.find(
                (s) => s.sellerDbId === seller.sellerDbId
              );
              !productsLoading && setSelectedSeller(sellerExists || seller);
            }}
            setOpenProductFoundModal={setOpenProductFoundModal}
          />
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="flex-1 lg:flex-[unset]"
          />
          <SortingDropdown
            value={sorting}
            onChange={(action: string) => setSorting(action)}
          />
        </div>
        <div className="px-3.5 pb-4 pt-1 overflow-auto flex flex-col gap-2.5 h-[calc(100vh-168px)] lg:h-[calc(100vh-108px)]">
          <AllSellerCard
            onSelect={() => setSelectedSeller("all")}
            selected={selectedSeller === "all"}
            seller={{}}
            showBookmarkedSellersOnly={showBookmarkedSellersOnly}
            setShowBookmarkedSellersOnly={setShowBookmarkedSellersOnly}
            unreadCount={(sellers || []).reduce(
              (acc, seller) => acc + Number(seller.totalUnreadProducts || 0),
              0
            )}
          />
          {filteredSellerList.map((seller) => (
            <SellerCard
              selected={selectedSeller?.id === seller?.id}
              showTags={show}
              key={seller?.id}
              seller={seller}
              onSelect={() => !productsLoading && setSelectedSeller(seller)}
              onUpdate={() => refetchSellers()}
              onView={() => setUserView(seller)}
              onRemove={() => setRemoveConfirm(seller)}
              onEdit={() => {
                setSelectedSeller(seller);
                setIsEditInfoOpen(true);
              }}
            />
          ))}
          {!sellersLoading && filteredSellerList?.length === 0 && (
            <EmptySeller onAddSeller={handleAddSeller} />
          )}
        </div>
      </div>

      {userView && (
        <SellerDetails
          open={!!userView}
          seller={userView}
          onClose={() => setUserView(null)}
          refetch={() => {
            refetchSellers();
            fetchSellers();
          }}
          isActiveSellerReached={isActiveSellerReached}
          onSubscription={onSubscription}
        />
      )}
      {!!removeConfirm && (
        <DeleteSellerConfirm
          open={!!removeConfirm}
          onClose={() => setRemoveConfirm(null)}
          seller={removeConfirm}
          onSuccess={() => {
            refetchSellers();
            fetchSellers();
          }}
        />
      )}
      {isEditInfoOpen && (
        <SellerFormModal
          open={isEditInfoOpen}
          setOpen={() => {
            setIsEditInfoOpen(!isEditInfoOpen);
          }}
          onSuccess={() => {
            refetchSellers();
            fetchSellers();
            setIsEditInfoOpen(false);
          }}
          seller={selectedSeller}
          isActiveSellerReached={isActiveSellerReached}
          onSubscription={onSubscription}
        />
      )}
    </div>
  );
}
