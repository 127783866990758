import { Button } from "../../../../@/components/ui/button";
import { ReactComponent as CaretSortIcon } from "../../../../assets/icons/caret-sort.svg";

import { SelectInput } from "./SelectInput";

export function Pagination({
  pagination,
  onPaginationChange,
  totalPages,
  total,
  products,
  productsLoading,
}) {
  const renderPaginationButton = () => {
    return (
      <div className="flex items-center gap-2 font-inter">
        <Button
          variant={"outline"}
          color="gray"
          onClick={() =>
            onPaginationChange({ ...pagination, page: pagination.page - 1 })
          }
          className={`px-3 h-8 flex gap-1 items-center rounded-[6px] border border-slate200`}
          disabled={pagination.page === 0 || productsLoading}
        >
          Previous
        </Button>
        <Button
          variant={"outline"}
          color="gray"
          onClick={() =>
            onPaginationChange({ ...pagination, page: pagination.page + 1 })
          }
          disabled={
            !totalPages || pagination.page === totalPages - 1 || productsLoading
          }
          className={`px-3 h-8 flex gap-1 items-center rounded-[6px] border border-slate200`}
        >
          Next
        </Button>
      </div>
    );
  };

  const renderResultView = () => {
    let count = (pagination.page + 1) * pagination.limit;
    if (count > total) {
      count = total;
    }
    return (
      <div>
        <span className="text-slate-500 text-sm font-inter">
          {count} of {total} result(s) shown.
        </span>
      </div>
    );
  };

  const renderRowsPerPage = () => {
    return (
      <div className="flex items-center gap-2 [&_.rt-SelectIcon]:!hidden [&_.custom-label]:!mr-0 [&_.trigger]:!px-4 font-inter">
        <span className="hidden lg:block text-slate900 text-sm font-medium">
          Rows per page
        </span>
        <SelectInput
          list={[
            { value: 10, text: "10" },
            { value: 25, text: "25" },
            { value: 50, text: "50" },
            { value: 100, text: "100" },
          ]}
          onChange={(value) =>
            onPaginationChange({ page: 0, limit: Number(value) })
          }
          value={pagination.limit}
          className="!min-w-[76px] !w-[76px]"
          label={
            <div className="flex items-center gap-3">
              <span className="text-slate900 text-sm font-medium">
                {pagination.limit}
              </span>
              <CaretSortIcon className="[&_path]:stroke-slate-400" />
            </div>
          }
        />
      </div>
    );
  };

  const renderPageCount = () => {
    return (
      <div className="text-sm text-slate-900 font-medium font-inter">
        Page {pagination.page + 1} of {totalPages}
      </div>
    );
  };

  return (
    <>
      <div className="hidden md:flex justify-between bg-white items-center py-2 px-4 border-t">
        {renderResultView()}
        <div className="flex gap-4 md:gap-11 items-center">
          {renderRowsPerPage()}
          {renderPageCount()}
          {renderPaginationButton()}
        </div>
      </div>
      <div className="flex md:hidden flex-col gap-2 bg-white py-2 px-4 border-t">
        <div className="flex justify-between items-center">
          {renderResultView()}
        </div>
        <div className="flex justify-between items-center">
          {renderPageCount()}
          {renderRowsPerPage()}
          {renderPaginationButton()}
        </div>
      </div>
    </>
  );
}
