import { Button } from "../../../@/components/ui/button";
import { SearchInput } from "../../../components/Search";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { TagsModal } from "./TagsModal";
import { FilterModal } from "./FilterModal";
import { useState } from "react";
import { StatusFilterSelect } from "./StatusFilterSelect";

export function FilterView({
  search,
  setSearch,
  onAddSeller,
  filters,
  onClear,
  onChange,
}) {
  const [filtersToShow, setFiltersToShow] = useState<any>([]);
  return (
    <div className="pl-3 pr-3 md:pr-[18px]">
      <div className="flex justify-between gap-2 items-center">
        <div className="flex items-center gap-2">
          <SearchInput
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="flex-1 md:flex-[unset]"
          />
          <div className="hidden md:block">
            {filtersToShow.includes("status") && (
              <StatusFilterSelect
                onChange={(value) => {
                  onChange({
                    ...filters,
                    status: value,
                  });
                }}
                value={filters.status}
              />
            )}
          </div>
        </div>
        <div className="flex gap-2 md:gap-3 items-center">
          <TagsModal />
          <FilterModal
            filters={filtersToShow}
            onClear={() => {
              setFiltersToShow([]);
              onChange({});
            }}
            onChange={(filter) => {
              if (filtersToShow.includes(filter.name)) {
                setFiltersToShow(
                  filtersToShow.filter((f) => f !== filter.name)
                );
              } else {
                setFiltersToShow([...filtersToShow, filter.name]);
              }
            }}
          />

          <Button
            onClick={() => onAddSeller()}
            color="black"
            className="flex gap-1.5 items-center !h-8 px-2 md:!px-4"
          >
            <PlusIcon className="w-4 h-4" />
            <span className="hidden md:block">Add a seller</span>
          </Button>
        </div>
      </div>
      <div className="block mt-2 md:hidden">
        {filtersToShow.includes("status") && (
          <StatusFilterSelect
            onChange={(value) => {
              onChange({
                ...filters,
                status: value,
              });
            }}
            value={filters.status}
          />
        )}
      </div>
    </div>
  );
}
