import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { setToken, setUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { Layout } from "../../../components/Layout";
import FormField from "../../../components/FormField";
import Button from "../../../components/Button";
import { SocialLogin } from "../../../components/SocialLogin";
import useGoogleLogin from "../../../helpers/hooks/useGoogleLogin";
import { useToast } from "../../../@/components/ui/use-toast";
import { DeviceUUID } from "device-uuid";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/g,
      "Password must contain at least one uppercase and one lowercase letter and one special character"
    ),
  confirm: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
  fullName: Yup.string().required("Name is required"),
});

function RegisterForm() {
  // regex for email
  const navigate = useNavigate();
  const { toast } = useToast();

  return (
    <Layout>
      <Formik
        initialValues={{ email: "", password: "" }}
        isInitialValid={false}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          api
            .register({
              ...values,
              userType: "U",
            })
            .then(async (res) => {
              actions.setSubmitting(false);
              const result = res.data;
              if (result?.accessToken) {
                toast({
                  description:
                    "You've successfully created a stealth seller account.",
                });
                setToken(result?.accessToken);
                if (result?.id) {
                  const userResponse = await api.getUserProfile(result?.id);
                  setUser({ ...userResponse });
                  const uuid = new DeviceUUID().get();
                  const deviceAgent = navigator.userAgent;
                  await api.registerDevice(result?.id, {
                    deviceAgent,
                    deviceId: uuid,
                  });
                  await api.setActiveStatus(result?.id);
                }
                navigate("/dashboard");
              }
            })
            .catch((err) => {
              actions.setSubmitting(false);
              toast({
                description:
                  err.response.data.message || "Invalid email or password",
                variant: "destructive",
              });

              console.log(err);
            });
        }}
      >
        <RegisterFormContent />
      </Formik>
    </Layout>
  );
}

function RegisterFormContent() {
  const { isValid, isSubmitting } = useFormikContext();
  const { handleGoogleLogin } = useGoogleLogin();
  const googleLogin = async (response: any) => {
    handleGoogleLogin(response);
  };

  return (
    <div className="grid items-center h-full justify-center">
      <div className="grid">
        <Form className="lg:w-[380px] w-[calc(100vw-2rem)] grid gap-[1.75rem] mb-[1.5rem]">
          <h1 className="text-[1.5rem]">Register</h1>
          <div className="grid gap-4">
            <FormField
              label="Full Name"
              name="fullName"
              type="text"
              placeholder="Enter your full name"
            />
            <FormField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your registered email"
            />
            <FormField
              label="Password"
              name="password"
              type="password"
              placeholder="Enter your password"
            />
            <FormField
              label="Confirm Password"
              name="confirm"
              type="password"
              placeholder="Re-enter password"
            />
          </div>

          <div className="grid gap-3">
            <Button
              className="w-full"
              disabled={!isValid}
              type="submit"
              loading={isSubmitting}
            >
              Register
            </Button>

            <SocialLogin onGoogleLogin={googleLogin} />
          </div>
        </Form>
        <div className="text-center font-inter font-medium text-sm">
          <span>Already have an account?</span>{" "}
          <Link to="/login" className="underline">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;
