import { ReactComponent as CheckIcon } from "../../../assets/icons/check2.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { Popover } from "@radix-ui/themes";
import { Button } from "../../../@/components/ui/button";

const filterList = [
  {
    name: "status",
    title: "Status",
  },
];

export function FilterModal({ onClear, filters, onChange }) {
  return (
    <div>
      <Popover.Root>
        <Popover.Trigger>
          <Button
            variant="outline"
            className="rounded-[6px] !px-3 !py-1 flex gap-1.5 items-center bg-white border border-slate200 !h-8"
          >
            <FilterIcon />
            Filters
          </Button>
        </Popover.Trigger>
        <Popover.Content minWidth="200px" maxWidth="200px" className="!p-0">
          <div className="text-slate-700 flex flex-col justify-center px-3 h-10 text-sm font-semibold leading-[20px]">
            Seller Filters
          </div>
          <div className="py-1 px-1 border-y border-y-slate-200">
            {filterList.map((filter) => (
              <Button
                variant="link"
                className="flex px-2 py-1 items-center gap-2 w-full !no-underline justify-start hover:bg-slate-50"
                key={filter.name}
                onClick={() => onChange(filter)}
              >
                <div className="w-4 h-4">
                  {filters.includes(filter.name) ? <CheckIcon /> : null}
                </div>
                <div className="text-sm text-slate-700 leading-[20px] rounded-[6px] px-1.5 py-[2px] w-fit">
                  {filter.title}
                </div>
              </Button>
            ))}
          </div>
          <Button
            variant="link"
            className="!no-underline text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
            onClick={onClear}
          >
            Clear filters
          </Button>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}
