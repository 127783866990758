import { getUser, setUser } from "../../helpers/utils";
import { api } from "../../helpers/api";
import CustomButton from "../../components/Button";
import Modal from "../../components/Modal";
import FormField from "../../components/FormField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import ConfirmIcon from "../../assets/icons/check.svg";
import { useToast } from "../../@/components/ui/use-toast";

export default function ChangeEmailModal({
  open,
  onClose,
  onBack,
}: {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
}) {
  const user = getUser();
  const [verificationSent, setVerificationSent] = useState(false);
  const [success, setSuccess] = useState(false);
  const { toast } = useToast();

  return (
    <Modal
      open={open}
      title={success ? "" : "Change email"}
      handleModal={() => {
        onClose();
        setSuccess(false);
        setVerificationSent(false);
      }}
      onBack={
        success
          ? undefined
          : () => {
              onBack();
              setSuccess(false);
              setVerificationSent(false);
            }
      }
      noClose={success}
    >
      <Formik
        initialValues={{
          email: "",
          otp: "",
        }}
        isInitialValid={false}
        onSubmit={(values, actions) => {
          if (!verificationSent) {
            api
              .initiateEmailUpdate(user.id, values.email)
              .then(() => {
                toast({
                  title: "Verification code sent!",
                  description:
                    "Please check your email for the verification code",
                });
                setVerificationSent(true);
                actions.setSubmitting(false);
              })
              .catch((err) => {
                toast({
                  title: "Error!",
                  description:
                    err?.response?.data?.message ||
                    "An error occurred. Please try again",
                  variant: "destructive",
                });
                console.log(err);
                actions.setSubmitting(false);
              });
          } else {
            api
              .updateEmail(user.id, values.otp)
              .then(() => {
                setSuccess(true);
                setVerificationSent(false);
                actions.setSubmitting(false);
                // update email in local storage
                setUser({ ...user, email: values.email });
              })
              .catch((err) => {
                toast({
                  title: "Error!",
                  description:
                    err?.response?.data?.message ||
                    "An error occurred. Please try again",
                  variant: "destructive",
                });
                console.log(err);
                actions.setSubmitting(false);
              });
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
        })}
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => {
          if (success)
            return (
              <div className="grid">
                <img src={ConfirmIcon} alt="confirm" className="mx-auto" />
                <div className="grid gap-2 text-center mb-5 mt-6">
                  <span className="font-grotesk font-[500] text-[1.25rem] text-slate-900">
                    Email changed!
                  </span>
                  <span>
                    Your email has been changed to <br />'{values.email}'
                  </span>
                </div>
                <CustomButton
                  onClick={() => {
                    onClose();
                    setSuccess(false);
                    setVerificationSent(false);
                  }}
                  className="w-fit mx-auto"
                >
                  Awesome!
                </CustomButton>
              </div>
            );
          return (
            <Form className="mt-5 grid gap-4">
              <FormField
                label="Your new email address"
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
              />
              {verificationSent && (
                <FormField
                  label="Verification code"
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder="Enter verification code"
                />
              )}
              <CustomButton
                type="submit"
                className="w-fit ml-auto"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
              >
                {verificationSent ? "Verify" : "Continue"}
              </CustomButton>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
