import { DropdownMenu, Popover } from "@radix-ui/themes";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { Button } from "../../../@/components/ui/button";
import { ReactComponent as ExternalIcon } from "../../../assets/icons/external-link.svg";

export function SellerListRowAction({
  onView,
  onPause,
  onRemove,
  onActive,
  status,
  onEdit,
  seller,
}: {
  onView: () => void;
  onPause: () => void;
  onRemove: () => void;
  onActive: () => void;
  status: string;
  onEdit?: any;
  seller?: any;
}) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button
          variant="outline"
          className="border flex justify-center items-center border-slate200 !px-0 !py-0 !w-6 !h-6 rounded-[6px]"
          onClick={(e) => e.stopPropagation()}
        >
          <DotsVerticalIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="!p-[5px] flex flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          variant={"outline"}
          className="!shadow-none !border-none w-[147px] justify-start !px-2 !py-1.5 !h-8"
          onClick={onView}
        >
          View details
        </Button>
        {onEdit && (
          <Button
            variant={"outline"}
            className="!shadow-none !border-none w-[147px] justify-start !px-2 !py-1.5 !h-8"
            onClick={onEdit}
          >
            Edit info
          </Button>
        )}
        <Button
          variant={"outline"}
          className="!shadow-none !border-none w-[147px] justify-start !px-2 !py-1.5 !h-8"
          onClick={status === "A" ? onPause : onActive}
        >
          {status === "A" ? "Pause" : "Activate"} seller
        </Button>

        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger className="!pl-2 font-medium text-sm border border-input [&_path]:!fill-black !text-black !bg-background hover:!bg-slate-50 hover:text-accent-foreground !shadow-none !border-none ">
            In another tab
          </DropdownMenu.SubTrigger>
          <DropdownMenu.SubContent className="ml-4">
            <a href={seller?.amazonUrl} target="_blank" rel="noreferrer">
              <Button
                variant={"outline"}
                className="!shadow-none !text-slate-700 group hover:!bg-slate-100 !border-none w-[147px] justify-between items-center !text-sm !font-medium !px-2 !py-1.5 !h-8"
              >
                Amazon
                <div className="hidden group-hover:block">
                  <ExternalIcon />
                </div>
              </Button>
            </a>
            <a href={seller?.keepaUrl} target="_blank" rel="noreferrer">
              <Button
                variant={"outline"}
                className="!shadow-none !text-slate-700 group hover:!bg-slate-100 !border-none w-[147px] justify-between items-center !text-sm !font-medium !px-2 !py-1.5 !h-8"
              >
                Keepa
                <div className="hidden group-hover:block">
                  <ExternalIcon />
                </div>
              </Button>
            </a>
            <a href={seller?.sellerAmpUrl} target="_blank" rel="noreferrer">
              <Button
                variant={"outline"}
                className="!shadow-none !text-slate-700 group hover:!bg-slate-100 !border-none w-[147px] justify-between items-center !text-sm !font-medium !px-2 !py-1.5 !h-8"
              >
                Seller Amp
                <div className="hidden group-hover:block">
                  <ExternalIcon />
                </div>
              </Button>
            </a>
          </DropdownMenu.SubContent>
        </DropdownMenu.Sub>
        <Button
          variant={"outline"}
          className="!shadow-none !text-[#DC3F0F] !border-none w-[147px] justify-start !px-2 !py-1.5 !h-8"
          onClick={onRemove}
        >
          Remove
        </Button>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
  // return (
  //   <Popover.Root>
  //     <Popover.Trigger>
  //       <Button
  //         variant="outline"
  //         className="border flex justify-center items-center border-slate200 !px-0 !py-0 !w-6 !h-6 rounded-[6px]"
  //         onClick={(e) => e.stopPropagation()}
  //       >
  //         <DotsVerticalIcon />
  //       </Button>
  //     </Popover.Trigger>
  //     <Popover.Content
  //       className="!p-[5px] flex flex-col"
  //       onClick={(e) => e.stopPropagation()}
  //     >
  //       <Button
  //         variant={"outline"}
  //         className="!shadow-none !border-none w-[147px] justify-start !px-2 !py-1.5 !h-8"
  //         onClick={onView}
  //       >
  //         View details
  //       </Button>
  //       {onEdit && (
  //         <Button
  //           variant={"outline"}
  //           className="!shadow-none !border-none w-[147px] justify-start !px-2 !py-1.5 !h-8"
  //           onClick={onEdit}
  //         >
  //           Edit info
  //         </Button>
  //       )}
  //       <Button
  //         variant={"outline"}
  //         className="!shadow-none !border-none w-[147px] justify-start !px-2 !py-1.5 !h-8"
  //         onClick={status === "A" ? onPause : onActive}
  //       >
  //         {status === "A" ? "Pause" : "Activate"} seller
  //       </Button>
  //       <Button
  //         variant={"outline"}
  //         className="!shadow-none !text-[#DC3F0F] !border-none w-[147px] justify-start !px-2 !py-1.5 !h-8"
  //         onClick={onRemove}
  //       >
  //         Remove
  //       </Button>
  //     </Popover.Content>
  //   </Popover.Root>
  // );
}
