import { useNavigate } from "react-router-dom";
import { Button } from "../../../../@/components/ui/button";
import { ReactComponent as SellersIcon } from "../../../../assets/icons/sellers.svg";

export function EmptyProductFeed() {
  const navigate = useNavigate();
  return (
    <div className="flex-1 flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-3 font-inter">
        <h2 className="text-center font-grotesk text-[1.75rem] font-medium leading-[120%]">
          No results yet
        </h2>
        <p className="text-sm">
          We will notify you as soon as new products are added,
        </p>
        <Button
          className="flex flex-row gap-1.5"
          onClick={() => {
            navigate("/sellers");
          }}
        >
          <SellersIcon className="[&_path]:stroke-white h-4 w-4" />
          Manage Sellers
        </Button>
      </div>
    </div>
  );
}
