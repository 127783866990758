import { useEffect, useState } from "react";
import { getUser } from "../../helpers/utils";
import { useToast } from "../../@/components/ui/use-toast";
import { api } from "../../helpers/api";
import Modal from "../../components/Modal";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../@/components/ui/select";
import { countries } from "../../helpers/consts";
import CustomButton from "../../components/Button";

export function ChangeMarketPlace({
  isOpen,
  onClose,
  onUpdate,
  type,
}: {
  isOpen: boolean;
  onClose: () => void;
  onUpdate?: () => void;
  type?: string;
}) {
  const user = getUser();
  const isEdit = type === "edit";
  const [form, setForm] = useState({
    country: "",
    experience: user?.experience,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const handleSubmit = () => {
    setIsLoading(true);

    api
      .putUserProfile(user.id, form)
      .then(async (res) => {
        toast({
          description: "Marketplace updated successfully",
        });
        setIsLoading(false);
        onClose();
        // parse user in local storage to update the experience and country
        const user = getUser();
        user.country = form.country;
        localStorage.setItem("user", JSON.stringify(user));
      })
      .catch(() => {
        toast({
          description: "Account setup failed",
          variant: "destructive",
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen && user) {
      setForm({
        country: user.country,
        experience: user?.experience,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      title={isEdit ? "Update Marketplace" : "AMAZON MARKETPLACE"}
      handleModal={onClose}
    >
      <div className="grid gap-3 mt-2">
        {/* <h2 className="font-grotesk text-[0.875rem] font-[500] text-slate-900">
          AMAZON MARKETPLACE
        </h2> */}
        <p>
          {isEdit
            ? `Select the Amazon marketplace where you sell your products.`
            : `Please specify the Amazon marketplace where you conduct your sales and
          your experience!`}
        </p>
        <div className="grid gap-4">
          <label className="grid gap-2">
            <span className="text-[rgba(51,65,85,1)]">Country</span>
            <Select
              onValueChange={(value) => {
                setForm({
                  ...form,
                  country: value,
                });
              }}
              value={form.country}
            >
              <SelectTrigger className="w-full text-black">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup className="max-h-[200px]">
                  {/* <SelectLabel>Fruits</SelectLabel> */}
                  {countries.map((country) => (
                    <SelectItem key={country.code} value={country.code}>
                      {country.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </label>
          <CustomButton
            onClick={handleSubmit}
            disabled={!form.country}
            loading={isLoading}
          >
            Done
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
}
