import { Tooltip } from "@radix-ui/themes";
import { toast } from "../../../../@/components/ui/use-toast";
import { api } from "../../../../helpers/api";
import { BookmarkButton } from "./BookmarkButton";

export function AllSellerCard({
  selected,
  seller,
  onSelect,
  showBookmarkedSellersOnly,
  setShowBookmarkedSellersOnly,
  unreadCount,
}) {
  const isBookmarked = true;
  const handleBookmark = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setShowBookmarkedSellersOnly(!showBookmarkedSellersOnly);
    //   try {
    //     const hasFavorite = seller?.tags?.find(
    //       (tag) => tag.toLowerCase() === favoriteTagInfo.name.toLowerCase()
    //     );
    //     let payload: any = {
    //       id: seller.id,
    //     };
    //     const tags = (seller.tags || []).filter((e) => e);
    //     if (hasFavorite) {
    //       payload["tags"] = tags.filter(
    //         (tag) => tag.toLowerCase() !== favoriteTagInfo.name.toLowerCase()
    //       );
    //     } else {
    //       payload["tags"] = [...tags, favoriteTagInfo.name];
    //     }
    //     // update seller
    //     const response = await api.updateSeller(user.id, payload);
    //     if ([200, 201].includes(response.status)) {
    //       onUpdate();
    //     }
    //   } catch (error: any) {
    //     console.log(error);
    //     toast({
    //       title: "Something went wrong",
    //       description: error?.response?.data?.message,
    //     });
    //   }
  };
  return (
    <div
      className={`border border-[#E2E8F0] !h-12 shadow-field outline-offset-2 p-3 rounded-md `}
      style={{
        outline: selected ? "2px solid #0F172A" : "none",
      }}
      role="button"
      onClick={onSelect}
    >
      <div className="flex justify-between items-start gap-5">
        <div className="flex-1 flex justify-between items-center">
          <span className="text-sm font-semibold text-slate-900 leading-5">
            All Sellers
          </span>
          <div className="flex items-center justify-end gap-1">
            {unreadCount > 0 && (
              <div className="rounded-[6px] bg-slate-900 text-xs font-medium py-[0.25rem] px-1.5 text-white">
                {unreadCount}
              </div>
            )}
            <BookmarkButton
              onClick={handleBookmark}
              bookmarked={showBookmarkedSellersOnly}
              tooltip="Show bookmarked sellers only"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
