export const FILTER_NAME = {
  CATEGORIES: "categories",
  BUY_BOX: "buyBox",
  OFFERS: "offers",
  SALES_RANK: "salesRank",
  MONTHLY_SOLD: "monthlySold",
  TIME_POSTED: "timePosted",
  NEWLY_POSTED: "newlyPosted",
  AMAZON_ON_LISTING: "amazonListing",
  STOREFRONT_FULFILLMENT_METHOD: "storefrontFulfillmentMethod",
};
export const productFeedFilters = [
  {
    name: FILTER_NAME.CATEGORIES,
    title: "Categories",
  },
  {
    name: FILTER_NAME.BUY_BOX,
    title: "Buy Box",
  },
  {
    name: FILTER_NAME.OFFERS,
    title: "Offers",
  },
  {
    name: FILTER_NAME.SALES_RANK,
    title: "Sales Rank",
  },
  {
    name: FILTER_NAME.MONTHLY_SOLD,
    title: "Monthly Sold",
  },
  {
    name: FILTER_NAME.TIME_POSTED,
    title: "Time Posted",
  },
  {
    name: FILTER_NAME.NEWLY_POSTED,
    title: "Newly Posted",
  },
  {
    name: FILTER_NAME.AMAZON_ON_LISTING,
    title: "Amazon on Listing",
  },
  {
    name: FILTER_NAME.STOREFRONT_FULFILLMENT_METHOD,
    title: "Fulfillment Method",
  },
];

export const productCategories = [
  "Appliances",
  "Arts, Crafts & Sewing",
  "Automotive",
  "Baby Products",
  "Beauty & Personal Care",
  "Books",
  "CDs & Vinyl",
  "Cell Phones & Accessories",
  "Collectibles & Fine Art",
  "Electronics",
  "Grocery & Gourmet Food",
  "Health & Household",
  "Home & Kitchen",
  "Industrial & Scientific",
  "Musical Instruments",
  "Office Products",
  "Patio, Lawn & Garden",
  "Pet Supplies",
  "Sports & Outdoors",
  "Tools & Home Improvement",
  "Toys & Games",
  "Video Games",
];
