import { useEffect, useState } from "react";
import { FilterView } from "./components/FilterView";
import { getUser } from "../../helpers/utils";
import { ProductFeedCard } from "../product-feed/components/ProductFeedCard/ProductFeedCard";
import { Pagination } from "../product-feed/Feed/components/Pagination";
import { useSavedProductFeed } from "./context/saved-product-feed-context";
import { EmptyFeed } from "./components/EmptyFeed";
import Spinner from "../../components/Spinners";

export function Feed() {
  const {
    products = [],
    savedProducts,
    onSaveProduct,
    productsLoading,
    pagination,
    onPaginationChange,
    totalPages,
    total,
    readProducts,
    refetchReadProducts,
    refetch,
  } = useSavedProductFeed() as any;

  useEffect(() => {
    if (!productsLoading) {
      // scroll to top product feed list
      const productFeedList = document.getElementById(
        "saved-product-feed-list"
      );
      if (productFeedList) {
        productFeedList.scrollTo(0, 0);
      }
    }
  }, [productsLoading]);

  console.log("products", products, total, totalPages, productsLoading);
  return (
    <div className="w-full lg:w-auto flex flex-1 h-full flex-col relative justify-between">
      <div className="hidden lg:flex bg-white justify-between items-center pl-4 py-3.5 pr-3 border-b border-b-slate-300 h-[3.25rem]">
        <div className="flex items-center gap-2.5">
          <h2 className="font-grotesk text-[1rem]">Saved Products</h2>
          <div className="px-2 py-0.5 bg-slate-50 rounded-[6px] border border-slate-200 justify-center items-center gap-2.5 flex">
            <div className="text-[#4B5563] text-xs font-medium leading-tight">
              {products.length}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 pt-3 pb-0  flex flex-col overflow-auto">
        <FilterView />

        {!productsLoading && products?.length > 0 && (
          <div
            id="saved-product-feed-list"
            className="overflow-auto my-[14px] pl-3 pr-3 md:pr-[18px]"
          >
            <div className="h-full flex flex-col gap-2">
              {(products || [])?.map((product) => (
                <ProductFeedCard
                  key={product.id}
                  product={product}
                  selectedSeller={{}}
                  isSaved={true}
                  onSaveProduct={() => onSaveProduct(product)}
                  onUpdate={() => {
                    refetch(undefined, undefined, false);
                    refetchReadProducts();
                  }}
                  isRead={
                    !(
                      product?.isNew &&
                      !readProducts?.find((p) => p === product.id)
                    )
                  }
                />
              ))}
            </div>
          </div>
        )}
        {productsLoading && (
          <div
            className={`flex justify-center [&_svg]:text-slate-900 ${
              products?.length === 0 ? "flex-1 items-center" : ""
            }`}
          >
            <Spinner />
          </div>
        )}
        {products?.length === 0 && !productsLoading && <EmptyFeed />}
      </div>
      <Pagination
        total={total}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        totalPages={totalPages}
        products={products}
        productsLoading={productsLoading}
      />
    </div>
  );
}
