import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { Button } from "../../../../@/components/ui/button";
import { StatusButton } from "../../components/StatusButton";
import { RenderTags } from "../../components/RenderTags";
import { SellerListRowAction } from "../../components/SellerListRowAction";
import moment from "moment";

export function MobileSkeletonSellerCard() {
  return (
    <div
      className={`border border-slate200 bg-white flex flex-col gap-2.5 p-3 rounded-md `}
    >
      <div className="flex justify-between items-start gap-5 mb-1">
        <div className="flex flex-col gap-2">
          <div className="flex items-center flex-wrap gap-2">
            <div className="w-32 h-4 bg-slate-200 animate-pulse transition duration-50 rounded-[6px]" />
            <div className="w-12 h-4 bg-slate-200 animate-pulse transition duration-50 rounded-[6px]" />
          </div>
          <div className="w-40 h-4 bg-slate-200 animate-pulse transition duration-50 rounded-[6px]" />
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-8 h-8 bg-slate-200 animate-pulse transition duration-50 rounded-[6px]" />
        </div>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        <RenderTags tags={[]} />
        <div className="w-12 h-4 bg-slate-200 animate-pulse transition duration-50 rounded-[6px]" />
        <div className="w-12 h-4 bg-slate-200 animate-pulse transition duration-50 rounded-[6px]" />
        <div className="w-4 h-4 bg-slate-200 animate-pulse transition duration-50 rounded-[6px]" />
      </div>
      <div className="w-32 h-4 bg-slate-200 animate-pulse transition duration-50 rounded-[6px]" />
    </div>
  );
}

export function MobileSellerCard({
  seller,
  setUserView,
  handlePause,
  handleActive,
  handleRemove,
  onEdit,
}: {
  seller: any;
  setUserView: any;
  handlePause: any;
  handleActive: any;
  handleRemove: any;
  onEdit?: any;
}) {
  return (
    <div
      className={`border border-slate200 bg-white flex flex-col gap-2.5 p-3 rounded-md `}
    >
      <div className="flex justify-between items-start gap-5 mb-1">
        <div className="flex flex-col gap-1">
          <div className="flex items-center flex-wrap gap-2">
            <span className="text-sm font-bold text-slate-900 leading-[23.8px]">
              {seller?.nickName || seller?.sellerName}
            </span>
            <StatusButton status={seller.status} />
          </div>
          <span className="text-xs font-medium text-slate-600 leading-[20.4px]">
            {seller?.sellerId}
          </span>
        </div>
        <div className="flex gap-1 items-center">
          <SellerListRowAction
            onView={() => setUserView(seller)}
            onPause={() => handlePause(seller)}
            onActive={() => handleActive(seller)}
            onRemove={() => handleRemove(seller)}
            status={seller?.status}
            onEdit={onEdit}
          />
        </div>
      </div>
      <div className="flex flex-wrap items-center gap-1">
        <RenderTags tags={seller.tags} />
      </div>
      <div className="text-[#111827] text-xs leading-[20.4px]">
        Added on{" "}
        {moment(seller?.createdAt).utc().local().format("MMM DD, YYYY")}
      </div>
    </div>
  );
}
