import React, { useEffect, useState, useCallback } from "react";
import { api } from "../../../helpers/api";
import { getUser } from "../../../helpers/utils";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { toast } from "../../../@/components/ui/use-toast";
import useProducts from "../../product-feed/context/useProducts";
import { NumberFilter } from "../../product-feed/SellerList/constant";
import useSellerProducts from "./useSellerProducts";

export const SellerProductContext = React.createContext({});

export function SellerProductProvider({ children }) {
  const [appliedFilter, setAppliedFilter] = useState<any>({});
  const [selectedSeller, setSelectedSeller] = useState<any>(null);
  const [productFilter, setProductFilter] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const user = getUser();
  const {
    filters,
    updateFilters,
    setSellerId,
    onSearch,
    state,
    pagination,
    onPaginationChange,
    totalPages,
    products,
    isLoading,
    total,
    loadMore,
  } = useSellerProducts() as any;

  const {
    data: savedProducts,
    isLoading: savedProductsLoading,
    refetch: refetchSavedProducts,
  } = useQuery(
    ["savedProducts", user.id],
    () => api.getSavedProductIds(user.id),
    {
      enabled: !!user.id,
    }
  );

  const {
    data: readProducts,
    isLoading: readProductsLoading,
    refetch: refetchReadProducts,
  } = useQuery(
    ["readProducts", user.id],
    () => api.fetchUserReadProducts(user.id),
    {
      enabled: !!user.id,
    }
  );

  const onSaveProduct = async (product: any) => {
    try {
      const isSaved = savedProducts?.products?.find((p) => p.id === product.id);
      const API = isSaved ? api.removeSavedProduct : api.saveProduct;
      const response = await API(user.id, product.id);
      if ([200, 201].includes(response.status)) {
        await refetchSavedProducts();
        toast({
          title: isSaved
            ? "Product removed successfully"
            : "Product Saved successfully",
        });
      }
    } catch (e) {
      console.log(e);
      toast({
        title: "Something went wrong, please try again later.",
      });
    }
  };

  const onApply = useCallback(
    (filter) => {
      // apply filter
      setAppliedFilter((prev) => ({ ...prev, [filter.name]: filter }));
      if (filter.name === "categories") {
        updateFilters({
          categories: filter.value,
        });
        return;
      }
      if (filter.name === "timePosted") {
        updateFilters({
          minTimePosted: filter.startDate
            ? moment(filter.startDate).format("YYYY-MM-DD")
            : filter.startDate,
          maxTimePosted: filter.endDate
            ? moment(filter.endDate).format("YYYY-MM-DD")
            : filter.endDate,
        });
        return;
      }
      // evaluate filter & set to useProducts
      // filter: { name: "", condition: "grater_than/less_than/between", fromValue: "100", toValue: "200" }
      const minField = `min${filter.name}`;
      const maxField = `max${filter.name}`;
      if (filter.condition === NumberFilter.BETWEEN) {
        updateFilters({
          [minField]: filter.fromValue,
          [maxField]: filter.toValue,
        });
      } else if (filter.condition === NumberFilter.GRATER_THAN) {
        updateFilters({
          [minField]: filter.fromValue,
        });
      } else if (filter.condition === NumberFilter.LESS_THAN) {
        updateFilters({
          [minField]: 0,
          [maxField]: filter.fromValue,
        });
      }
    },
    [updateFilters]
  );

  const onClearFilter = useCallback(
    (filterName) => {
      if (filterName === "categories") {
        updateFilters({
          categories: undefined,
        });

        setAppliedFilter((prev) => {
          delete prev[filterName];
          return { ...prev };
        });
        return;
      }
      const minField = `min${filterName}`;
      const maxField = `max${filterName}`;
      updateFilters({
        [minField]: undefined,
        [maxField]: undefined,
      });
      setAppliedFilter((prev) => {
        delete prev[filterName];
        return { ...prev };
      });
    },
    [updateFilters]
  );

  const onClearAllFilters = useCallback(() => {
    updateFilters({}, true);
    setAppliedFilter({});
  }, [updateFilters]);

  useEffect(() => {
    if (selectedSeller) {
      setSellerId(selectedSeller.sellerDbId);
    }
  }, [selectedSeller]);

  const values = {
    selectedSeller,
    setSelectedSeller,
    loading,
    setLoading,
    productFilter,
    setProductFilter,
    onApply,
    onSearch,
    searchState: state,
    onClearFilter,
    onClearAllFilters,
    products: products || [],
    productsLoading: isLoading,
    pagination,
    onPaginationChange,
    totalPages,
    total,
    loadMore,
    appliedFilter,
    setAppliedFilter,
    onSaveProduct,
    refetchSavedProducts,
    savedProducts,
    readProducts,
    refetchReadProducts,
  };

  return (
    <SellerProductContext.Provider value={values}>
      {children}
    </SellerProductContext.Provider>
  );
}

export function useSellerProduct() {
  const context = React.useContext(SellerProductContext);
  if (context === undefined) {
    throw new Error("useTags must be used within a SellerProductProvider");
  }
  return context;
}
