import { Button } from "../../../@/components/ui/button";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";

export function EmptySeller({ onAddSeller }) {
  return (
    <div className="flex justify-center h-[calc(100vh-300px)] lg:h-[calc(100vh-200px)]">
      <div className="flex flex-col gap-3 justify-center">
        <div className="text-center">No sellers found.</div>
        <Button
          onClick={() => onAddSeller()}
          color="black"
          className="w-fit flex gap-1.5 items-center !h-8"
        >
          <PlusIcon className="w-4 h-4" />
          Add a seller
        </Button>
      </div>
    </div>
  );
}
