import { sellerStatus } from "../../product-feed/SellerList/constant";
import { ColorBoxIcon } from "../../product-feed/components/ColorBoxIcon";

export function StatusButton({ status }) {
  const statusInfo = sellerStatus.find((color) => color.name === status);
  return (
    <div className="w-fit border border-slate200 bg-slate50 rounded-[6px] px-1.5 py-[1px] flex items-center gap-1">
      <ColorBoxIcon bg={statusInfo?.bg} border={statusInfo?.border} />
      <span className="capitalize text-slate-900 text-[12px] font-medium">
        {statusInfo?.title}
      </span>
    </div>
  );
}
