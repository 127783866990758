import apiClient from "./client";
import { convertDollarToCent } from "./utils";

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/login", data);
  },
  register: (data: any) => {
    return apiClient.post("/auth/register", data);
  },
  forgotPassword: (data: any) => {
    return apiClient.post("/auth/forgot-password", data);
  },
  resetPassword: (data: any) => {
    return apiClient.post("/auth/reset-password", data);
  },
  getUserProfile: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  patchPassword: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}/password`, data);
  },
  putUserProfile: (id: string, data: any) => {
    return apiClient.put(`/users/${id}`, data);
  },
  getUserSubscription: (id: string) => {
    return apiClient.get(`/users/${id}/subscriptions`).then((res) => res.data);
  },
  setActiveStatus: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
  getStripe: (id: string, tier: "ST" | "PR" | "MX" | "UL") => {
    return apiClient
      .get(`/users/${id}/stripe-connect-url`, {
        params: {
          // redirectUrl: "https://dev.app.stealthseller.co/dashboard",
          // redirectUrl: "http://localhost:3002/dashboard",
          // dynamicRedirectUrl
          redirectUrl: window.location.origin + "/dashboard",
          planType: "M",
          tier: tier,
        },
      })
      .then((res) => res.data);
  },
  patchStripe: (
    id: string,
    tier: "ST" | "PR" | "MX" | "UL",
    subscriptionId: string
  ) => {
    return apiClient.patch(`/users/${id}/subscriptions/${subscriptionId}`, {
      tier: tier,
      planType: "M",
    });
  },
  customerStripePortal: (id: string) => {
    return apiClient
      .get(`/users/${id}/stripe-customer-portal-url`, {
        params: {
          // redirectUrl: "https://dev.app.stealthseller.co/dashboard",
          // redirectUrl: "http://localhost:3002/dashboard",
          // dynamicRedirectUrl
          redirectUrl: window.location.origin + "/dashboard",
        },
      })
      .then((res) => res.data);
  },
  cancelSubscription: (id: string, subscriptionId: string) => {
    return apiClient
      .delete(`/users/${id}/subscriptions/${subscriptionId}`)
      .then((res) => res.data);
  },
  reactivateSubscription: (userId: string, subscriptionId: string) => {
    const url = `/users/${userId}/subscriptions/${subscriptionId}/reactivate`;
    return apiClient.patch(url).then((res) => res.data);
  },
  getUser: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  saveTags: (userId: string, tags: string[]) => {
    return apiClient.post(`/users/${userId}/tags`, {
      tags,
    });
  },
  getTags: (userId: string) => {
    return apiClient.get(`/users/${userId}/tags`).then((res) => res.data);
  },
  deleteTag: (tagId: string) => {
    return apiClient.delete(`/tags/${tagId}`);
  },
  initiateEmailUpdate: (userId: string, email: string) => {
    return apiClient.post(`/users/${userId}/email/update/initiate`, {
      email,
    });
  },
  updateEmail: (userId: string, otp: string) => {
    return apiClient.post(`/users/${userId}/email/update`, {
      otp,
    });
  },
  addSeller: (userId: string, data: any) => {
    ///users/{id}/sellers
    const url = `/users/${userId}/sellers`;
    return apiClient.post(url, data);
  },
  getSellers: (userId: string) => {
    return apiClient.get(`/users/${userId}/sellers`).then((res) => res.data);
  },
  fetchSeller: (userId: string, id: string) => {
    const url = `/users/${userId}/sellers/${id}`;
    return apiClient.get(url).then((res) => res.data);
  },
  updateSeller: (userId: string, data: any) => {
    const url = `/users/${userId}/sellers/${data.id}`;
    return apiClient.patch(url, data);
  },
  removeSeller: (userId: string, id: string) => {
    const url = `/users/${userId}/sellers/${id}`;
    return apiClient.delete(url);
  },
  deleteUser: (userId: string) => {
    return apiClient.delete(`/users/${userId}`);
  },
  getCategories: (userId: string) => {
    return apiClient.get(`/users/${userId}/categories`).then((res) => res.data);
  },
  getProducts: (
    sellerId: string,
    data: any,
    signal: any,
    fetchAll = false,
    userId?: string
  ) => {
    console.log("api", data);
    let queryString = `?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      queryString += `&searchKeyword=${data.keyword}`;
    }
    if (data.categories && data.categories.length > 0) {
      queryString += `&categories=${encodeURIComponent(
        data.categories.join(",")
      )}`;
    }
    if (data.newlyPosted !== undefined) {
      queryString += `&isNew=${data.newlyPosted}`;
    }
    if (data.amazonListing !== undefined) {
      queryString += `&isOfferAmazon=${data.amazonListing}`;
    }
    if (data.storefrontFulfillmentMethod === "FBA") {
      queryString += `&isFBA=true`;
    }
    if (data.storefrontFulfillmentMethod === "FBM") {
      queryString += `&isFBM=true`;
    }

    if (data.minBuyBoxPrice !== undefined) {
      queryString += `&minBuyBoxPrice=${convertDollarToCent(
        Number(data.minBuyBoxPrice || 0)
      )}`;
    }
    if (data.maxBuyBoxPrice !== undefined) {
      queryString += `&maxBuyBoxPrice=${convertDollarToCent(
        Number(data.maxBuyBoxPrice || 0)
      )}`;
    }
    if (data.minOffersCount !== undefined) {
      queryString += `&minOffersCount=${data.minOffersCount}`;
    }
    if (data.maxOffersCount !== undefined) {
      queryString += `&maxOffersCount=${data.maxOffersCount}`;
    }
    if (data.minSalesRank !== undefined) {
      queryString += `&minSalesRank=${data.minSalesRank}`;
    }
    if (data.maxSalesRank !== undefined) {
      queryString += `&maxSalesRank=${data.maxSalesRank}`;
    }
    if (data.minMonthlySales !== undefined) {
      queryString += `&minMonthlySales=${data.minMonthlySales}`;
    }
    if (data.maxMonthlySales !== undefined) {
      queryString += `&maxMonthlySales=${data.maxMonthlySales}`;
    }
    if (data.minTimePosted !== undefined) {
      queryString += `&minTimePosted=${data.minTimePosted}`;
    }
    if (data.maxTimePosted !== undefined) {
      queryString += `&maxTimePosted=${data.maxTimePosted}`;
    }
    const url = fetchAll
      ? `/users/${userId}/sellers-products${queryString}`
      : `/sellers/${sellerId}/products${queryString}`;
    return apiClient
      .get(url, {
        signal,
      })
      .then((res) => res.data);
  },
  getGraph: (sellerId: string, productId: string) => {
    // /sellers/{id}/products/{productId}/graph
    return apiClient.get(`/sellers/${sellerId}/products/${productId}/graph`, {
      responseType: "blob",
    });
  },
  getSavedProductIds: (userId: string) => {
    // /users/{id}/productIds
    return apiClient.get(`/users/${userId}/productIds`).then((res) => res.data);
  },
  getSavedProducts: (userId: string, data: any) => {
    ///users/{id}/products
    let queryString = `?page=${data.page || 0}&limit=${data.limit || 20}`;

    if (data.keyword) {
      queryString += `&searchKeyword=${data.keyword}`;
    }
    // if (data.)
    if (data.newlyPosted !== undefined) {
      queryString += `&isNew=${data.newlyPosted}`;
    }
    if (data.amazonListing !== undefined) {
      queryString += `&isOfferAmazon=${data.amazonListing}`;
    }
    if (data.storefrontFulfillmentMethod === "FBA") {
      queryString += `&isFBA=true`;
    }
    if (data.storefrontFulfillmentMethod === "FBM") {
      queryString += `&isFBM=true`;
    }
    if (data.categories && data.categories.length > 0) {
      queryString += `&categories=${encodeURIComponent(
        data.categories.join(",")
      )}`;
    }
    if (data.minBuyBoxPrice !== undefined) {
      queryString += `&minBuyBoxPrice=${convertDollarToCent(
        Number(data.minBuyBoxPrice || 0)
      )}`;
    }
    if (data.maxBuyBoxPrice !== undefined) {
      queryString += `&maxBuyBoxPrice=${convertDollarToCent(
        Number(data.maxBuyBoxPrice || 0)
      )}`;
    }
    if (data.minOffersCount !== undefined) {
      queryString += `&minOffersCount=${data.minOffersCount}`;
    }
    if (data.maxOffersCount !== undefined) {
      queryString += `&maxOffersCount=${data.maxOffersCount}`;
    }
    if (data.minSalesRank !== undefined) {
      queryString += `&minSalesRank=${data.minSalesRank}`;
    }
    if (data.maxSalesRank !== undefined) {
      queryString += `&maxSalesRank=${data.maxSalesRank}`;
    }
    if (data.minMonthlySales !== undefined) {
      queryString += `&minMonthlySales=${data.minMonthlySales}`;
    }
    if (data.maxMonthlySales !== undefined) {
      queryString += `&maxMonthlySales=${data.maxMonthlySales}`;
    }
    if (data.minTimePosted !== undefined) {
      queryString += `&minTimePosted=${data.minTimePosted}`;
    }
    if (data.maxTimePosted !== undefined) {
      queryString += `&maxTimePosted=${data.maxTimePosted}`;
    }
    return apiClient
      .get(`/users/${userId}/products${queryString}`)
      .then((res) => res.data);
  },
  saveProduct: (userId: string, productId: string) => {
    // users/{id}/products/{productId}
    return apiClient.post(`/users/${userId}/products/${productId}`);
  },
  removeSavedProduct: (userId: string, productId: string) => {
    // users/{id}/products/{productId}
    return apiClient.delete(`/users/${userId}/products/${productId}`);
  },
  markAllAsRead: (userId: string) => {
    // /users/{id}/sellers-read
    return apiClient.post(`/users/${userId}/sellers-read`);
  },
  markAsRead: (userId: string, sellerId: string) => {
    return apiClient.post(`/users/${userId}/sellers/${sellerId}/read`);
  },
  markProductAsRead: (userId: string, sellerId: string, productId: string) => {
    return apiClient.post(
      `/users/${userId}/sellers/${sellerId}/products/${productId}/read`
    );
  },
  fetchUserReadProducts: (userId: string) => {
    return apiClient
      .get(`/users/${userId}/read-productIds`)
      .then((res) => res.data);
  },
  registerDevice: (
    userId: string,
    data: {
      deviceId: string;
      deviceAgent: string;
    }
  ) => {
    const url = `/users/${userId}/devices`;
    return apiClient.post(url, data);
  },
  updateUserActivity: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
};
