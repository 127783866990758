export const countries = [
  { name: "🇺🇸 United States", code: "US" },
  { name: "🇬🇧 United Kingdom", code: "UK" },
  { name: "🇩🇪 Germany", code: "DE" },
  { name: "🇫🇷 France", code: "FR" },
  { name: "🇯🇵 Japan", code: "JP" },
  { name: "🇨🇦 Canada", code: "CA" },
  { name: "🇨🇳 China", code: "CN" },
  { name: "🇮🇹 Italy", code: "IT" },
  { name: "🇪🇸 Spain", code: "ES" },
  { name: "🇮🇳 India", code: "IN" },
  { name: "🇲🇽 Mexico", code: "MX" },
  { name: "🇧🇷 Brazil", code: "BR" },
];

export const Subscription_Status = {
  PAST_DUE: "PastDue",
  ACTIVE: "active",
  CANCELLED: "cancelled",
};
