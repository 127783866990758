import { Drawer } from "vaul";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { SellerList } from "../SellerList";
import React from "react";

export function MobileSellerList({ open, onClose, setOpenProductFoundModal }) {
  const [container, setContainer] = React.useState<any>(null);

  return (
    <>
      <Drawer.Root open={open} onOpenChange={onClose}>
        <Drawer.Portal container={container}>
          <Drawer.Overlay className="fixed inset-0 bg-black/40" />
          <Drawer.Content className="radix-themes z-[9]  bg-white radix-themes !outline-none flex flex-col rounded-t-[10px] h-[calc(100vh-42px)] mt-24 fixed bottom-0 left-0 right-0">
            <button className="absolute -top-8 right-2" onClick={onClose}>
              <CloseIcon className="[&_path]:stroke-white w-6 h-6" />
            </button>
            <SellerList setOpenProductFoundModal={setOpenProductFoundModal} />
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
      <div ref={setContainer} />
    </>
  );
}
