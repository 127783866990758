import { Drawer } from "vaul";
import { Button } from "../../../@/components/ui/button";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as CalendarPlusIcon } from "../../../assets/icons/calendar-plus.svg";
import { ReactComponent as BookmarkIcon } from "../../../assets/icons/bookmark2.svg";
import { ReactComponent as BookmarkFilledIcon } from "../../../assets/icons/bookmark-filled.svg";

import { sellerStatus } from "../../product-feed/SellerList/constant";
import moment from "moment";
import { Products } from "./Products";
import { useEffect, useState } from "react";
import { SellerFormModal } from "../components/SellerFormModal";
import { getUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { DeleteSellerConfirm } from "../components/DeleteSellerConfirm";
import Spinner from "../../../components/Spinners";
import { SellerDetailsAction } from "./components/SellerDetailsAction";
import { useSellerProduct } from "../context/seller-product-context";
import { StatusButton } from "../components/StatusButton";
import { favoriteTagInfo } from "../components/FavoriteTag";
import { toast } from "../../../@/components/ui/use-toast";

export function SellerDetails({
  open,
  seller,
  onClose,
  refetch,
  isActiveSellerReached,
  onSubscription,
}) {
  const {
    products,
    productsLoading,
    setSelectedSeller,
    loadMore,
    total,
    savedProducts,
    onSaveProduct,
    refetchSavedProducts,
    readProducts,
    refetchReadProducts,
  } = useSellerProduct() as any;
  const [edit, setEdit] = useState(false);
  const [removeConfirm, setRemoveConfirm] = useState<any>(null);

  const [loading, setLoading] = useState(false);
  const [sellerDetails, setSellerDetails] = useState<any>(null);

  const statusInfo = sellerStatus.find(
    (color) => color.name === sellerDetails?.status
  );
  const user = getUser();

  const fetchSellerDetails = async (
    userId: string,
    id: string,
    shouldLoad = true
  ) => {
    try {
      shouldLoad && setLoading(true);
      const response = await api.fetchSeller(userId, id);
      setSellerDetails(response);
      setLoading(false);
    } catch (e) {}
  };

  const handleRefresh = (shouldLoad?: boolean) => {
    fetchSellerDetails(user.id, seller?.id, shouldLoad);
    refetch();
  };

  const isBookmarked = !!sellerDetails?.tags?.find(
    (tag) => tag.toLowerCase() === favoriteTagInfo.name.toLowerCase()
  );

  const handleBookmark = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const hasFavorite = sellerDetails?.tags?.find(
        (tag) => tag.toLowerCase() === favoriteTagInfo.name.toLowerCase()
      );
      let payload: any = {
        id: sellerDetails.id,
      };
      const tags = (sellerDetails.tags || []).filter((e) => e);
      if (hasFavorite) {
        payload["tags"] = tags.filter(
          (tag) => tag.toLowerCase() !== favoriteTagInfo.name.toLowerCase()
        );
      } else {
        payload["tags"] = [...tags, favoriteTagInfo.name];
      }
      // update seller
      const response = await api.updateSeller(user.id, payload);
      if ([200, 201].includes(response.status)) {
        handleRefresh(false);
      }
    } catch (error: any) {
      toast({
        title: "Something went wrong",
        description: error?.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    if (open && seller?.id && user.id) {
      fetchSellerDetails(user.id, seller?.id);
      setSelectedSeller(seller);
    }
  }, [open, seller, user.id]);

  return (
    <Drawer.Root open={open} onOpenChange={onClose}>
      <Drawer.Portal>
        <div className="fixed inset-0 bg-black/40">
          <Drawer.Content className="bg-white select-text radix-themes !outline-none flex flex-col rounded-t-[10px] h-[96vh] mt-24 fixed bottom-0 left-0 right-0">
            {loading ? (
              <div className="[&_svg]:text-slate-900 h-full w-full flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="lg:max-w-[1030px] w-full mx-auto pt-5 p-4 md:p-10 !font-inter">
                  <div>
                    <h2 className="font-grotesk text-lg font-medium text-black">
                      Seller details
                    </h2>
                    <div>
                      <Button
                        onClick={onClose}
                        variant="link"
                        className="!no-underline absolute top-5 right-5 pr-0 pt-0"
                      >
                        <CloseIcon className="w-4 h-4" />
                      </Button>
                    </div>
                  </div>
                  <div className="mt-[18px] flex items-start gap-[14px] border border-[#D1D5DB] rounded-md p-3 md:p-4">
                    <div className="flex-1 flex justify-between gap-[14px]">
                      <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                          <span className="!font-grotesk text-lg leading-[120%]">
                            {sellerDetails?.nickName ||
                              sellerDetails?.sellerName}
                          </span>
                          <StatusButton status={sellerDetails?.status} />
                        </div>
                        <div className="bg-slate-50 w-fit text-xs font-medium rounded-[6px] border border-slate-200 px-1.5 py-[2px] text-slate-900">
                          {sellerDetails?.sellerId}
                        </div>
                        <div className="flex flex-wrap gap-1.5">
                          {sellerDetails?.tags
                            ?.filter((e) => e)
                            ?.map((tag, i) => (
                              <div
                                key={i}
                                className="bg-slate-50 w-fit text-xs font-medium rounded-[6px] border border-slate-200 px-1.5 py-[2px] text-slate-900"
                              >
                                {tag}
                              </div>
                            ))}
                        </div>
                        {sellerDetails?.lastTimePosted && (
                          <div className="md:hidden !h-[22px] flex items-center gap-1 font-medium bg-slate-50 w-fit text-xs leading-[18px] rounded-[6px] border border-slate-200 px-[0.375rem] py-[2px] text-slate-900">
                            <CalendarPlusIcon className="w-3 h-3" />
                            <span>
                              Last posted{" "}
                              {moment(sellerDetails?.lastTimePosted)
                                .utc()
                                .local()
                                .fromNow()}
                            </span>
                          </div>
                        )}
                        <div className="md:hidden !h-[22px] font-medium bg-slate-50 w-fit text-xs leading-[18px] rounded-[6px] border border-slate-200 px-[0.375rem] py-[2px] text-slate-900">
                          Added on{" "}
                          {moment(sellerDetails?.createdAt)
                            .utc()
                            .local()
                            .format("MMM DD, YYYY")}
                        </div>
                      </div>
                      <div className="flex flex-col justify-between">
                        <div className="md:hidden">
                          <SellerDetailsAction
                            onEdit={() => setEdit(true)}
                            onRemove={() => setRemoveConfirm(seller)}
                          />
                        </div>
                        <div className="hidden md:flex items-center flex-row gap-2 justify-end">
                          <Button
                            // onClick={() => setRemoveConfirm(seller)}
                            onClick={handleBookmark}
                            variant="outline"
                            className="px-2 py-1 !h-8 flex flex-row gap-1 items-center"
                          >
                            {isBookmarked ? (
                              <BookmarkFilledIcon className="bookmark-filled-icon" />
                            ) : (
                              <BookmarkIcon className="w-4 h-4" />
                            )}
                            <span className="text-sm font-medium leading-[23.8px]">
                              Bookmark
                            </span>
                          </Button>
                          <Button
                            onClick={() => setRemoveConfirm(seller)}
                            variant="outline"
                            className="px-2 py-1 flex flex-row gap-1 items-center !h-8"
                          >
                            <TrashIcon className="w-4 h-4 [&_path]:stroke-black" />
                            <span className="text-sm font-medium leading-[23.8px]">
                              Delete
                            </span>
                          </Button>
                          <Button
                            onClick={() => setEdit(true)}
                            className="px-2 py-1 flex flex-row gap-1 items-center !h-8"
                          >
                            <EditIcon className="w-4 h-4 [&_path]:stroke-white" />
                            <span className="text-sm font-medium leading-[23.8px]">
                              Edit
                            </span>
                          </Button>
                        </div>
                        <div className="flex flex-row justify-end gap-1">
                          {sellerDetails?.lastTimePosted && (
                            <div className="hidden !h-[22px] items-center gap-1 md:flex font-medium bg-slate-50 w-fit text-xs leading-[18px] rounded-[6px] border border-slate-200 px-[0.375rem] py-[2px] text-slate-900">
                              <CalendarPlusIcon className="w-3 h-3" />
                              <span>
                                Last posted{" "}
                                {moment(sellerDetails?.lastTimePosted)
                                  .utc()
                                  .local()
                                  .fromNow()}
                              </span>
                            </div>
                          )}
                          <div className="hidden !h-[22px] md:block font-medium bg-slate-50 w-fit text-xs leading-[18px] rounded-[6px] border border-slate-200 px-[0.375rem] py-[2px] text-slate-900">
                            Added on{" "}
                            {moment(sellerDetails?.createdAt)
                              .utc()
                              .local()
                              .format("MMM DD, YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Products
                    seller={seller}
                    products={products || []}
                    isLoading={productsLoading}
                    loadMore={loadMore}
                    total={total}
                    savedProducts={savedProducts}
                    readProducts={readProducts}
                    onSaveProduct={onSaveProduct}
                    refetchReadProducts={refetchReadProducts}
                  />
                </div>
              </>
            )}
            {edit && sellerDetails && (
              <SellerFormModal
                open={edit}
                setOpen={setEdit}
                onSuccess={handleRefresh}
                seller={sellerDetails}
                isActiveSellerReached={isActiveSellerReached}
                onSubscription={onSubscription}
              />
            )}
            {!!removeConfirm && (
              <DeleteSellerConfirm
                open={!!removeConfirm}
                onClose={() => setRemoveConfirm(null)}
                seller={removeConfirm}
                onSuccess={() => {
                  refetch();
                  onClose();
                }}
              />
            )}
          </Drawer.Content>
        </div>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
