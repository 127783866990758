import { Button } from "../../../../@/components/ui/button";
import { Dialog, Tooltip } from "@radix-ui/themes";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/info.svg";
import moment from "moment";
import React from "react";

export function SellerProductsFoundModal({ open, setOpen, seller, onExplore }) {
  const handleExplore = () => {
    console.log("Explore");
    onExplore();
    setOpen(false);
  };
  const [container, setContainer] = React.useState<any>(null);
  return (
    <>
      <Dialog.Root open={open} onOpenChange={() => setOpen(false)}>
        <Dialog.Content container={container} maxWidth={"420px"}>
          <div className="font-grotesk text-[1.25rem] font-[500] flex justify-between tracking-normal leading-[120%]">
            <h3>Seller Products Found</h3>
          </div>
          <div className="grid gap-3 my-3">
            <p>
              {seller?.sellerName || seller?.nickName} is already part of our
              network with products. Explore their feed now.
            </p>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium leading-[20px]">
                Total posted within last 7 days
              </label>
              <Tooltip
                className="px-1.5 py-[2px] rounded-[6px]"
                content={
                  <div className="text-xs font-medium leading-[18px]">
                    Total posted since monitored{" "}
                    <span className="text-slate-500">
                      {seller?.totalProducts}
                    </span>
                  </div>
                }
              >
                <div className="flex w-fit items-center text-xs font-medium leading-[18px] gap-1.5 rounded-[6px] border border-slate-200 bg-slate-50 py-[1px] px-1.5">
                  {seller?.totalProductsSince7days}{" "}
                  <InfoIcon className="w-3 h-3" />
                </div>
              </Tooltip>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium leading-[20px]">
                Monitored since
              </label>
              <Tooltip
                className="px-1.5 py-[2px] rounded-[6px]"
                content={moment(seller?.createdAt).format("MMM DD, YYYY")}
              >
                <div className="flex w-fit items-center text-xs font-medium leading-[18px] gap-1.5 rounded-[6px] border border-slate-200 bg-slate-50 py-[1px] px-1.5">
                  {moment(seller?.createdAt).fromNow()}
                </div>
              </Tooltip>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm font-medium leading-[20px]">
                Last posted
              </label>
              <Tooltip
                className="px-1.5 py-[2px] rounded-[6px]"
                content={moment(seller?.lastTimePosted).format("MMM DD, YYYY")}
              >
                <div className="flex w-fit items-center text-xs font-medium leading-[18px] gap-1.5 rounded-[6px] border border-slate-200 bg-slate-50 py-[1px] px-1.5">
                  {moment(seller?.lastTimePosted).fromNow()}
                </div>
              </Tooltip>
            </div>
          </div>
          <Button
            onClick={handleExplore}
            // disabled={disabled}
            className="!w-full gap-1.5 !h-10"
          >
            Explore Now
          </Button>
          <Dialog.Close className="mt-3">
            <Button
              // disabled={disabled}
              className="!w-full gap-1.5 !h-10"
              variant="outline"
            >
              Dismiss
            </Button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Root>
      <div ref={setContainer} />
    </>
  );
}
