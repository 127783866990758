import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import ConfirmIcon from "../../../assets/icons/check.svg";
import { api } from "../../../helpers/api";
import FormField from "../../../components/FormField";
import Button from "../../../components/Button";
import { Layout } from "../../../components/Layout";
import { useToast } from "../../../@/components/ui/use-toast";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/g,
      "Password must contain at least one uppercase and one lowercase letter and one special character"
    ),
  confirm: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
});
export default function ResetPassword() {
  const [success, setSuccess] = useState(false);
  // get query param

  return (
    <Layout>
      {success ? (
        <ResetPasswordSuccess />
      ) : (
        <ResetPasswordForm toggleSuccess={() => setSuccess(true)} />
      )}
    </Layout>
  );
}

function ResetPasswordSuccess() {
  return (
    <div className="grid items-center h-full justify-center text-center lg:w-[380px] w-[calc(100vw-2rem)] mx-auto">
      <div className="grid gap-[1.5rem]">
        <img
          src={ConfirmIcon}
          alt="confirm"
          className="w-[80px] h-[80px] mx-auto"
        />
        <div className="grid gap-4">
          <h1 className="text-[1.25rem]">Password changed!</h1>
          <p className="font-[400]">
            Your password has been changed successfully!
          </p>
        </div>
        <div className="text-center">
          <Link to="/login">
            <Button>Login</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function ResetPasswordForm({ toggleSuccess }: { toggleSuccess: () => void }) {
  const location = useLocation();
  const { toast } = useToast();
  const rt = new URLSearchParams(location.search).get("rt");
  return (
    <Formik
      initialValues={{ password: "", confirm: "" }}
      validationSchema={validationSchema}
      isInitialValid={false}
      onSubmit={(values, actions) => {
        api
          .resetPassword({
            ...values,
            resetPasswordToken: rt,
          })
          .then(() => {
            actions.setSubmitting(false);
            toggleSuccess();
          })
          .catch((err) => {
            actions.setSubmitting(false);
            toast({
              description: "There was an error resetting your password.",
              variant: "destructive",
            });
          });
      }}
    >
      <ResetPasswordFormContent />
    </Formik>
  );
}

function ResetPasswordFormContent() {
  const { isValid, isSubmitting } = useFormikContext();

  return (
    <div className="grid items-center h-full justify-center">
      <div className="grid">
        <Form className="lg:w-[380px] w-[calc(100vw-2rem)] grid gap-[1.75rem] mb-[1.5rem]">
          <div className="grid gap-3">
            <h1 className="text-[1.5rem]">Reset password</h1>
            <p className="text-[0.875rem] text-[#475569]">
              Create a new password.
            </p>
          </div>
          <div className="grid gap-4">
            <FormField
              label="Password"
              name="password"
              type="password"
              placeholder="Enter your password"
            />
            <FormField
              label="Confirm Password"
              name="confirm"
              type="password"
              placeholder="Re-enter password"
            />
          </div>
          <Button
            className="w-full"
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
          >
            Reset password
          </Button>
        </Form>
      </div>
    </div>
  );
}
