import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { setToken, setUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { Layout } from "../../../components/Layout";
import FormField from "../../../components/FormField";
import Button from "../../../components/Button";
import { SocialLogin } from "../../../components/SocialLogin";
import useGoogleLogin from "../../../helpers/hooks/useGoogleLogin";
import { useToast } from "../../../@/components/ui/use-toast";
import { DeviceUUID } from "device-uuid";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/g,
      "Password must contain at least one uppercase and at least one lowercase letter and at least one special character"
    ),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  return (
    <Layout>
      <Formik
        initialValues={{ email: "", password: "" }}
        isInitialValid={false}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          api
            .login({
              ...values,
              userType: "U",
            })
            .then(async (res) => {
              actions.setSubmitting(false);
              localStorage.setItem("token", res.data.accessToken);
              setToken(res.data?.accessToken);
              if (res.data?.id) {
                const userResponse = await api.getUserProfile(res.data?.id);
                setUser({ ...res.data, ...userResponse });

                const uuid = new DeviceUUID().get();
                const deviceAgent = navigator.userAgent;
                await api.registerDevice(res.data?.id, {
                  deviceAgent,
                  deviceId: uuid,
                });
                await api.setActiveStatus(res.data?.id);
                console.log("device id", uuid);
              }
              navigate("/dashboard");
            })
            .catch((err) => {
              actions.setSubmitting(false);

              toast({
                description: "Invalid email or password",
                variant: "destructive",
              });
            });
        }}
      >
        <LoginFormContent />
      </Formik>
    </Layout>
  );
};

const LoginFormContent = () => {
  const { isValid, isSubmitting } = useFormikContext();
  const { handleGoogleLogin } = useGoogleLogin();

  const googleLogin = async (response: any) => {
    handleGoogleLogin(response);
  };

  return (
    <div className="grid items-center h-full justify-center">
      <div className="grid">
        <Form className="lg:w-[380px] w-[calc(100vw-2rem)] grid gap-[1.75rem] mb-[1.5rem]">
          <h1 className="text-[1.5rem]">Login</h1>
          <div className="grid gap-4">
            <FormField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
            />
            <FormField
              label="Password"
              name="password"
              type="password"
              placeholder="Enter your password"
              forgotLink
            />
          </div>
          <div className="grid gap-3">
            <Button
              className="w-full"
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
            >
              Login
            </Button>

            <SocialLogin onGoogleLogin={googleLogin} />
          </div>
        </Form>

        <div className="text-center font-inter font-medium text-sm">
          <span>Don’t have an account?</span>{" "}
          <Link to="/register" className="underline">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
