import { Popover, CheckboxGroup } from "@radix-ui/themes";
import { ReactComponent as DownIcon } from "../../../../assets/icons/chevron-down.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check2.svg";
import { ReactComponent as RoundedPlusIcon } from "../../../../assets/icons/rounded-plus.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../assets/icons/check-mark.svg";

import { useEffect, useState } from "react";
import { Button } from "../../../../@/components/ui/button";
import { SearchInput } from "../../../../components/Search";
import { Chip } from "../../components/Chip";
import { productCategories } from "../constant";
import { api } from "../../../../helpers/api";
import { useUser } from "../../../auth/context/user-context";
import { getUser } from "../../../../helpers/utils";

const filterTags = (tags, search) => {
  if (!search) return tags;
  return tags.filter((tag) => tag.toLowerCase().includes(search.toLowerCase()));
};

const showFavorite = (name, search) => {
  if (!search) return true;
  if (name.includes(search)) {
    return true;
  }
  return false;
};

export const tagsToSlice = (tags) => {
  // Maximum 40 characters combined all tags
  // Show as much as possible tags until 40 characters
  let numberOfTags = 0;
  let totalLength = 0;
  for (let i = 0; i < tags.length; i++) {
    totalLength += tags[i].length;
    if (totalLength > 40) {
      break;
    }
    numberOfTags++;
  }
  return numberOfTags;
};

export function CategorySelect({
  value = [],
  onChange,
  onApply,
  onClear,
  appliedValue,
}: {
  value?: string[];
  onChange: (value: string[]) => void;
  onApply: () => void;
  onClear: () => void;
  appliedValue: any;
}) {
  const { categories } = useUser() as any;
  const [search, setSearch] = useState("");
  const handleChange = (tag) => {
    if (value.includes(tag)) {
      onChange(value.filter((t) => t !== tag));
    } else {
      onChange([...value, tag]);
    }
  };

  console.log("value", value, appliedValue);
  return (
    <>
      <Popover.Root>
        <Popover.Trigger className="w-fit !px-2 !py-2 !rounded-[6px] !h-8">
          <button className="shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)] flex items-center gap-2 border border-dashed border-slate-200 rounded-[6px] bg-white px-[13px] py-[5px]">
            <div className="flex gap-2 items-center">
              <RoundedPlusIcon className="w-4 h-4" />
              <span className="text-xs font-medium leading-[18px] text-slate-900">
                Categories
              </span>
            </div>
            <div className="border-l border-l-slate-200 h-4" />
            <div className="bg-slate-50 leading-[18px] rounded-[6px] py-[2px] px-1.5 text-slate-900 text-xs">
              {appliedValue?.length ? (
                <>{appliedValue?.length} Selected</>
              ) : (
                "All categories"
              )}
            </div>
          </button>
        </Popover.Trigger>
        <Popover.Content minWidth="250px" maxWidth="250px" className="!p-0">
          <SearchInput
            className="!border-none !shadow-none !outline-none w-full !px-1 !pr-3 !py-2 !h-8 !m-0"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="border-y border-y-slate-200 p-1 flex flex-col justify-start h-[200px] overflow-auto">
            <div className="flex flex-col">
              {filterTags(categories, search)?.map((tag, i) => (
                <button
                  type="button"
                  className="flex flex-1 cursor-pointer px-2 py-[5px] items-center gap-2 !w-full !no-underline justify-start hover:bg-slate-50"
                  key={tag}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChange(tag);
                  }}
                >
                  <div className="w-4 h-4">
                    {(value || []).includes(tag) && (
                      <CheckMarkIcon className="w-4 h-4 [&>path]:!stroke-slate700" />
                    )}
                  </div>
                  <div
                    key={tag}
                    className="font-medium text-xs text-slate-900 rounded-[6px] px-1.5 py-[2px] w-fit"
                  >
                    {tag}
                  </div>
                </button>
              ))}
            </div>
          </div>
          <div className="flex">
            <Button
              variant="link"
              className="flex-1 !no-underline text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
              onClick={() => {
                setSearch("");
                onClear();
              }}
            >
              Clear
            </Button>
            <Popover.Close>
              <Button
                variant="link"
                className="flex-1 !no-underline !outline-none text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
                onClick={onApply}
                disabled={appliedValue?.length === 0 && value?.length === 0}
              >
                Apply
              </Button>
            </Popover.Close>
          </div>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
