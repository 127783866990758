import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import clsx from "clsx";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../@/components/ui/select";
import CustomButton from "../../components/Button";
import { getUser } from "../../helpers/utils";
import { api } from "../../helpers/api";
import { useToast } from "../../@/components/ui/use-toast";
import { useQuery } from "@tanstack/react-query";
import useStripeSession from "../../helpers/hooks/useSubscription";
import { cn } from "../../@/lib/utils";
import { countries } from "../../helpers/consts";
import { useSavedProductFeed } from "./context/saved-product-feed-context";
import { Feed } from "./Feed";
import IntercomComponent from "../../components/Intercom";

function SavedProductFeed() {
  const { refetch } = useSavedProductFeed() as any;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <IntercomComponent customPadding={64}>
      <div className="flex items-start h-full">
        <Feed />
      </div>
    </IntercomComponent>
  );
}

export default SavedProductFeed;
