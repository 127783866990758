import { Popover, CheckboxGroup } from "@radix-ui/themes";
import { ReactComponent as RoundedPlusIcon } from "../../../../assets/icons/rounded-plus.svg";
import { ReactComponent as CheckMarkIcon } from "../../../../assets/icons/check-mark.svg";

import { useEffect, useState } from "react";
import { Button } from "../../../../@/components/ui/button";
import { useUser } from "../../../auth/context/user-context";

export const tagsToSlice = (tags) => {
  // Maximum 40 characters combined all tags
  // Show as much as possible tags until 40 characters
  let numberOfTags = 0;
  let totalLength = 0;
  for (let i = 0; i < tags.length; i++) {
    totalLength += tags[i].length;
    if (totalLength > 40) {
      break;
    }
    numberOfTags++;
  }
  return numberOfTags;
};

export function BooleanFilterInput({
  value,
  onChange,
  onApply,
  onClear,
  appliedValue,
  label,
  defaultLabel,
}: {
  value?: boolean;
  onChange: (value?: boolean) => void;
  onApply: () => void;
  onClear: () => void;
  appliedValue: any;
  label: string;
  defaultLabel: string;
}) {
  const { categories } = useUser() as any;
  const [search, setSearch] = useState("");
  const handleChange = (val) => {
    if (value !== val) {
      onChange(val);
    }
  };

  console.log("value", value, appliedValue);
  return (
    <>
      <Popover.Root>
        <Popover.Trigger className="w-fit !px-2 !py-2 !rounded-[6px] !h-8">
          <button className="shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)] flex items-center gap-2 border border-dashed border-slate-200 rounded-[6px] bg-white px-[13px] py-[5px]">
            <div className="flex gap-2 items-center">
              <RoundedPlusIcon className="w-4 h-4" />
              <span className="text-xs font-medium leading-[18px] text-slate-900">
                {label}
              </span>
            </div>
            <div className="border-l border-l-slate-200 h-4" />
            <div className="bg-slate-50 leading-[18px] rounded-[6px] py-[2px] px-1.5 text-slate-900 text-xs">
              {value === undefined ? `all ${defaultLabel}` : `is ${value}`}
            </div>
          </button>
        </Popover.Trigger>
        <Popover.Content minWidth="170px" className="!p-0">
          <div className="border-y border-y-slate-200 p-1 flex flex-col justify-start overflow-auto">
            <div className="flex flex-col">
              <button
                type="button"
                className="flex flex-1 cursor-pointer px-2 py-[5px] items-center gap-2 !w-full !no-underline justify-start hover:bg-slate-50"
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(true);
                }}
              >
                <div className="w-4 h-4">
                  {value === true && (
                    <CheckMarkIcon className="w-4 h-4 [&>path]:!stroke-slate700" />
                  )}
                </div>
                <div className="font-medium text-xs text-slate-900 rounded-[6px] px-1.5 py-[2px] w-fit">
                  True
                </div>
              </button>
              <button
                type="button"
                className="flex flex-1 cursor-pointer px-2 py-[5px] items-center gap-2 !w-full !no-underline justify-start hover:bg-slate-50"
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(false);
                }}
              >
                <div className="w-4 h-4">
                  {value === false && (
                    <CheckMarkIcon className="w-4 h-4 [&>path]:!stroke-slate700" />
                  )}
                </div>
                <div className="font-medium text-xs text-slate-900 rounded-[6px] px-1.5 py-[2px] w-fit">
                  False
                </div>
              </button>
            </div>
          </div>
          <div className="flex">
            <Button
              variant="link"
              className="flex-1 !no-underline text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
              onClick={() => {
                setSearch("");
                onClear();
              }}
            >
              Clear
            </Button>
            <Popover.Close>
              <Button
                variant="link"
                className="flex-1 !no-underline !outline-none text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
                onClick={onApply}
                disabled={appliedValue === undefined && value === undefined}
              >
                Apply
              </Button>
            </Popover.Close>
          </div>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
