import { Popover } from "@radix-ui/themes";
import { ReactComponent as RoundedPlusIcon } from "../../../../assets/icons/rounded-plus.svg";
import { Button } from "../../../../@/components/ui/button";
import { ReactComponent as CheckMarkIcon } from "../../../../assets/icons/check-mark.svg";
import { ColorBoxIcon } from "../../components/ColorBoxIcon";

export function StoreFrontFilter({
  value,
  onChange,
  onClear,
  onApply,
  appliedValue,
}: {
  value?: string;
  onChange: (value: string) => void;
  onApply: () => void;
  onClear: () => void;
  appliedValue: any;
}) {
  const handleChange = (val) => {
    if (value !== val) {
      onChange(val);
    }
  };

  return (
    <>
      <Popover.Root>
        <Popover.Trigger className="w-fit !px-2 !py-2 !rounded-[6px] !h-8">
          <button className="shadow-[0px_1px_2px_0px_rgba(0,0,0,0.05)] flex items-center gap-2 border border-dashed border-slate-200 rounded-[6px] bg-white px-[13px] py-[5px]">
            <div className="flex gap-2 items-center">
              <RoundedPlusIcon className="w-4 h-4" />
              <span className="text-xs font-medium leading-[18px] text-slate-900">
                Storefront Fulfillment Method
              </span>
            </div>
            <div className="border-l border-l-slate-200 h-4" />
            <div className="bg-slate-50 leading-[18px] rounded-[6px] py-[2px] px-1.5 text-slate-900 text-xs">
              {value ? <>is {value}</> : "All Methods"}
            </div>
          </button>
        </Popover.Trigger>
        <Popover.Content minWidth="250px" maxWidth="250px" className="!p-0">
          <div className="border-y border-y-slate-200 p-1 flex flex-col justify-start overflow-auto">
            <div className="flex flex-col">
              <button
                type="button"
                className="flex flex-1 cursor-pointer px-2 py-[5px] items-center gap-2 !w-full !no-underline justify-start hover:bg-slate-50"
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange("FBA");
                }}
              >
                <div className="w-4 h-4">
                  {value === "FBA" && (
                    <CheckMarkIcon className="w-4 h-4 [&>path]:!stroke-slate700" />
                  )}
                </div>

                <div className="w-fit border border-slate200 bg-slate50 rounded-[6px] px-1.5 py-[1px] flex items-center gap-1">
                  <ColorBoxIcon bg={"bg-lime-50"} border={"border-[#14532D]"} />
                  <span className="capitalize text-slate-900 text-[12px] font-medium">
                    FBA
                  </span>
                </div>
              </button>
              <button
                type="button"
                className="flex flex-1 cursor-pointer px-2 py-[5px] items-center gap-2 !w-full !no-underline justify-start hover:bg-slate-50"
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange("FBM");
                }}
              >
                <div className="w-4 h-4">
                  {value === "FBM" && (
                    <CheckMarkIcon className="w-4 h-4 [&>path]:!stroke-slate700" />
                  )}
                </div>
                <div className="w-fit border border-slate200 bg-slate50 rounded-[6px] px-1.5 py-[1px] flex items-center gap-1">
                  <ColorBoxIcon
                    bg={"bg-[#FEFAE9]"}
                    border={"border-[#8A6D3A]"}
                  />
                  <span className="capitalize text-slate-900 text-[12px] font-medium">
                    FBM
                  </span>
                </div>
              </button>
            </div>
          </div>
          <div className="flex">
            <Button
              variant="link"
              className="flex-1 !no-underline text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
              onClick={() => {
                onClear();
              }}
            >
              Clear
            </Button>
            <Popover.Close>
              <Button
                variant="link"
                className="flex-1 !no-underline !outline-none text-slate-700 text-sm text-center w-full !h-10 hover:bg-slate-50"
                onClick={onApply}
                disabled={appliedValue === undefined && value === undefined}
              >
                Apply
              </Button>
            </Popover.Close>
          </div>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
