import { Button } from "../@/components/ui/button";
import LogoIcon from "../assets/icons/logo.svg";
import MinLogoIcon from "../assets/icons/min-logo.svg";
import { Link } from "react-router-dom";
import { ReactComponent as SidebarIcon } from "../assets/icons/sidebar-close.svg";
import { ReactComponent as PackageIcon } from "../assets/icons/box.svg";
import { ReactComponent as RightArrowIcon } from "../assets/icons/right-arrow.svg";

import { ReactComponent as BookmarkIcon } from "../assets/icons/bookmark.svg";
import { ReactComponent as SellersIcon } from "../assets/icons/sellers.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/setting.svg";
import { ReactComponent as MenuIcon } from "../assets/icons/menu.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";
import { useEffect, useState } from "react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "../@/components/ui/drawer";
import useViewportHeight from "../helpers/hooks/useViewportHeight";
import { cn } from "../@/lib/utils";
import CustomButton from "./Button";
import useStripeSession from "../helpers/hooks/useSubscription";
import { useUser } from "../pages/auth/context/user-context";
import { DEFAULT_SUBSCRIPTION_SELLER, subscriptionList } from "../constant";
import { useSavedProductFeed } from "../pages/saved-products/context/saved-product-feed-context";
import useResponsive from "../helpers/useResponsive";
import React from "react";
import { api } from "../helpers/api";
import { getUser } from "../helpers/utils";
import { useToast } from "../@/components/ui/use-toast";
import { Status, getSubscriptionStatus } from "../pages/settings/Settings";
import { Subscription_Status } from "../helpers/consts";
import { ColorBoxIcon } from "../pages/product-feed/components/ColorBoxIcon";

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { isSmallDevice } = useResponsive();
  const viewportHeight = useViewportHeight();
  const { activeSubscription, totalActiveUsers } = useUser() as any;

  const { products = [] } = useSavedProductFeed() as any;

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const subscriptionTier =
    activeSubscription && activeSubscription.length > 0
      ? activeSubscription[0].tier
      : null;
  const subscriptionInfo = subscriptionList.find(
    (sub) => sub?.tier === subscriptionTier
  );
  const isActiveSellerReached =
    totalActiveUsers >=
    (subscriptionInfo?.sellers || DEFAULT_SUBSCRIPTION_SELLER);
  const path = window.location.pathname.split("/").filter((x) => x !== "");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isSmallDevice) {
      setOpen(false);
    }
  }, [isSmallDevice]);

  // Desktop Sidebar
  return (
    <>
      <div className="lg:hidden flex">
        <Drawer open={open} direction="left" onOpenChange={setOpen}>
          <div className="w-full [&>button]:w-full [&>button]:p-3 relative top-0 left-0 bg-[transparent] z-[9] border-b-solid border-b-[1px] border-slate-300">
            <DrawerTrigger>
              <div className="flex gap-[0.625rem] items-center w-full align-center">
                <MenuIcon className="w-[1.5rem] h-[1.5rem] text-slate-500" />
                <h1 className="text-[1.125rem] font-[500]">
                  {path[0] === "dashboard"
                    ? "Products Feed"
                    : path[0] === "saved-products"
                    ? "Saved Products"
                    : path[0] === "sellers"
                    ? "Sellers"
                    : path[0] === "settings"
                    ? "Settings"
                    : path[0] === "subscription"
                    ? "Subscription"
                    : ""}
                </h1>
                {path[0] === "sellers" && (
                  <Button
                    variant={"outline"}
                    color="gray"
                    onClick={() => {}}
                    className={`px-1.5 h-6 flex gap-1 items-center rounded-[6px] bg-slate-50 border border-slate200`}
                  >
                    <span className="text-[#4B5563] text-xs font-medium">
                      {totalActiveUsers}/{subscriptionInfo?.sellers}
                    </span>
                  </Button>
                )}
                {path[0] === "saved-products" && (
                  <div className="px-2 py-0.5 bg-slate-50 rounded-[6px] border border-slate-200 justify-center items-center gap-2.5 flex">
                    <div className="text-[#4B5563] text-xs font-medium leading-tight">
                      {products.length}
                    </div>
                  </div>
                )}
              </div>
            </DrawerTrigger>
          </div>

          <DrawerContent>
            <div
              className="flex flex-col h-screen relative"
              style={{ height: viewportHeight }}
            >
              <div className="p-2 border-slate-300 border-b-solid border-b-[1px] flex justify-between gap-4 items-center pl-4">
                <Link to="/dashboard">
                  <img src={LogoIcon} alt="logo" className="h-[1.375rem]" />
                </Link>
                <DrawerClose>
                  <Button className="p-[0.625rem]" variant="ghost">
                    <CloseIcon className="w-[1.5rem] h-[1.5rem] text-slate-500" />
                  </Button>
                </DrawerClose>
              </div>
              <div className="w-full p-2">
                <div className="border-slate-300 border-b-solid border-b-[1px] w-full gap-2 grid pb-2">
                  <PackageLink isMobile={true} />
                  <BookmarkLink isMobile={true} />
                </div>
                <div className="w-full gap-2 grid pt-2">
                  <SellerLink isMobile={true} />
                </div>
              </div>
              <div className="absolute bottom-0 w-full">
                <Upgrade isMobile={true} />
                <SettingsLink isMobile={true} />
              </div>
            </div>
          </DrawerContent>
        </Drawer>
      </div>
      <>
        {collapsed ? (
          <div className="hidden lg:block">
            <div className="flex flex-col h-[100vh] border-r border-r-slate-300 relative">
              {/* <div className="p-2 border-slate-300 border-b-solid border-b-[1px] flex justify-between gap-4 items-center h-[3.25rem]">
            <Link to="/dashboard" className="mx-auto">
              <img src={MinLogoIcon} alt="logo" className="h-[1.375rem]" />
            </Link>
          </div> */}
              <div className="w-full p-2 !pb-[7px] border-slate-300 border-b-solid border-b-[1px] flex justify-center gap-4 items-center self-center">
                <Button
                  variant="outline"
                  onClick={toggleSidebar}
                  className="w-9 h-9 !p-0"
                >
                  <SidebarIcon className="text-slate-500 w-4 h-4 rotate-[180deg]" />
                </Button>
              </div>
              {/* <div className="p-2 border-slate-300 border-b-solid border-b-[1px] flex justify-between gap-4 items-center self-center">
            <Button
              variant="outline"
              onClick={toggleSidebar}
              className="w-9 h-9 !p-0"
            >
              <SidebarIcon
                className="text-slate-500 rotate-[180deg]"
                width={16}
                height={16}
              />
            </Button>
          </div> */}
              <div className="w-full p-2">
                <div className="border-slate-300 border-b-solid border-b-[1px] w-full gap-2 grid pb-2">
                  <PackageLink collapsed />
                  <BookmarkLink collapsed />
                </div>
                <div className="w-full gap-2 grid pt-2 justify-center">
                  <SellerLink collapsed />
                </div>
              </div>
              <div className="absolute bottom-0 w-full">
                <SettingsLink collapsed />
              </div>
            </div>
          </div>
        ) : (
          <div className="lg:flex flex-col h-[100vh] relative hidden w-[245px] border-r border-r-slate-300">
            <div className="p-2 border-slate-300 border-b-solid border-b-[1px] flex justify-between gap-4 items-center pl-4 h-[3.25rem]">
              <Link to="/dashboard">
                <img src={LogoIcon} alt="logo" className="h-[1.375rem]" />
              </Link>

              <Button
                className="p-[0.625rem] lg:flex hidden h-auto"
                variant="outline"
                onClick={toggleSidebar}
              >
                <SidebarIcon
                  className="text-slate-500"
                  width={16}
                  height={16}
                />
              </Button>
            </div>
            <div className="w-full p-2">
              <div className="border-slate-300 border-b-solid border-b-[1px] w-full gap-2 grid pb-2">
                <PackageLink />
                <BookmarkLink />
              </div>
              <div className="w-full gap-2 grid pt-2">
                <SellerLink />
              </div>
            </div>

            <div className="absolute bottom-0 w-full">
              <Upgrade />
              <SettingsLink />
            </div>
          </div>
        )}
      </>
    </>
  );
}

function PackageLink({
  collapsed,
  isMobile,
}: {
  collapsed?: boolean;
  isMobile?: boolean;
}) {
  return (
    <CustomLink
      to="/dashboard"
      activePath="/dashboard"
      Icon={PackageIcon}
      label="Products Feed"
      collapsed={collapsed}
      isMobile={isMobile}
    />
  );
}

function BookmarkLink({
  collapsed,
  isMobile,
}: {
  collapsed?: boolean;
  isMobile?: boolean;
}) {
  return (
    <CustomLink
      to="/saved-products"
      activePath="/saved-products"
      Icon={BookmarkIcon}
      label="Saved Products"
      collapsed={collapsed}
      isMobile={isMobile}
    />
  );
}

function SellerLink({
  collapsed,
  isMobile,
}: {
  collapsed?: boolean;
  isMobile?: boolean;
}) {
  return (
    <CustomLink
      to="/sellers"
      activePath="/sellers"
      Icon={SellersIcon}
      label="Sellers"
      collapsed={collapsed}
      isMobile={isMobile}
    />
  );
}

function SettingsLink({
  collapsed,
  isMobile,
}: {
  collapsed?: boolean;
  isMobile?: boolean;
}) {
  return (
    <div className="w-full px-2 py-1.5 gap-2 grid border-slate-300 border-t-solid border-t-[1px]">
      <CustomLink
        to="/settings"
        activePath="/settings"
        Icon={SettingsIcon}
        label="Settings"
        collapsed={collapsed}
        isMobile={isMobile}
      />
    </div>
  );
}

function CustomLink({
  to,
  activePath,
  Icon,
  label,
  collapsed = false,
  isMobile = false,
}) {
  const isActive =
    window.location.pathname === activePath &&
    window.location.pathname !== "/settings";

  if (isMobile) {
    return (
      <Link to={to}>
        <DrawerClose className="w-full">
          <Button
            className={cn(
              "flex gap-2 justify-start px-3 w-full",
              collapsed && "!justify-center"
            )}
            variant={isActive ? "default" : "ghost"}
          >
            <Icon
              className={cn({
                "[&>g>path]:stroke-white [&>path]:stroke-white": isActive,
              })}
            />
            {!collapsed && <span>{label}</span>}
          </Button>
        </DrawerClose>
      </Link>
    );
  }

  return (
    <Link to={to}>
      <Button
        className={cn(
          "flex gap-2 justify-start px-3 w-full",
          collapsed && "!justify-center !px-2"
        )}
        variant={isActive ? "default" : "ghost"}
      >
        <Icon
          className={cn({
            "[&>g>path]:stroke-white [&>path]:stroke-white": isActive,
          })}
        />
        {!collapsed && <span>{label}</span>}
      </Button>
    </Link>
  );
}

function Upgrade({ isMobile }: { isMobile?: boolean }) {
  const {
    handleGetStripeSession,
    activeSubscription,
    stripeLoading,
    nextPackage,
    isLoading,
    refetch,
  } = useStripeSession();
  const { toast } = useToast();
  const [stripeUpdateLoading, setStripeUpdateLoading] = useState(false);
  const [reactiveSuccess, setReactiveSuccess] = useState(false);

  const user = getUser();

  const subscriptionInfo = subscriptionList.find(
    (sub) => sub?.tier === nextPackage()
  );

  const currentSubscription = subscriptionList.find(
    (sub) => sub?.tier === activeSubscription?.[0]?.tier
  );
  const upgradeSellerCount =
    (subscriptionInfo?.sellers || 0) - (currentSubscription?.sellers || 0);

  const handleReactivateSubscription = () => {
    if (activeSubscription?.[0]?.subscriptionId) {
      setStripeUpdateLoading(true);

      api
        .reactivateSubscription(
          user.id,
          activeSubscription?.[0]?.subscriptionId
        )
        .then(async () => {
          await refetch();
          toast({
            description: "Subscription reactivated successfully",
          });
          setReactiveSuccess(true);
          setStripeUpdateLoading(false);
        })
        .catch(() => {
          setStripeUpdateLoading(false);
          toast({
            description: "Failed to reactivate subscription. Please try again.",
            variant: "destructive",
          });
        });
    }
  };
  const subscriptionStatus = getSubscriptionStatus(activeSubscription?.[0]);

  const isCanceled = subscriptionStatus === Subscription_Status.CANCELLED;

  if (isLoading) return null;
  if (nextPackage() === "") return null;

  if (isMobile) {
    return (
      <div className="py-4 px-3">
        <div className="w-full p-2 flex flex-col gap-[0.375rem] justify-between items-start border-solid border-slate-200 border-[1px] rounded-[8px]">
          <h2 className="font-grotesk text-[1rem]">
            {reactiveSuccess ? (
              "Successful!"
            ) : isCanceled ? (
              "We'll miss you..."
            ) : (
              <>Upgrade to {subscriptionInfo?.title || "Standard"}</>
            )}
          </h2>
          {reactiveSuccess ? (
            <>
              <div>
                <div className="w-fit border border-slate200 bg-slate50 rounded-[6px] px-1.5 py-[1px] flex items-center gap-1">
                  <ColorBoxIcon
                    bg="bg-[rgba(247,254,231,1)]"
                    border="border-[rgba(20,83,45,1)]"
                  />
                  <span className="text-slate-900 text-[12px] font-medium">
                    Reactivated
                  </span>
                </div>
                <div className="text-slate-500 text-xs font-medium mt-1.5">
                  Your membership was successfully reactivated!
                </div>
              </div>
              <Button
                onClick={() => setReactiveSuccess(false)}
                variant="outline"
                className="!h-10 mt-2.5"
              >
                Dismiss
              </Button>
            </>
          ) : isCanceled ? (
            <>
              <div>
                <Status
                  status={subscriptionStatus}
                  subscription={activeSubscription?.[0]}
                />
                <div className="text-slate-500 text-xs font-medium mt-1.5">
                  You will lose your data and access. Reconsider!
                </div>
              </div>

              <CustomButton
                className="w-full mt-[0.625rem]"
                onClick={() => handleReactivateSubscription()}
                // loading={stripeUpdateLoading}
                disabled={stripeUpdateLoading}
              >
                {stripeUpdateLoading ? "Reactivating..." : "Reactivate plan"}
              </CustomButton>
            </>
          ) : (
            <>
              <div className="px-2 bg-slate-50 border-solid border-[1px] border-slate-200 rounded-[6px] w-fit">
                <span className="text-[0.875rem] font-[500] text-slate-900">
                  +{upgradeSellerCount || DEFAULT_SUBSCRIPTION_SELLER} Sellers
                </span>
              </div>
              <CustomButton
                className="w-full mt-[0.625rem]"
                onClick={() => handleGetStripeSession({ type: nextPackage() })}
                loading={stripeLoading}
                disabled={stripeLoading}
              >
                <DrawerClose>Upgrade</DrawerClose>
              </CustomButton>
            </>
          )}
          {/* <p className="text-[#606060] text-[0.75rem]">
            Add more sellers today for 10% off. Use code:{" "}
            <span className="font-bold">MAX10</span>
          </p> */}
        </div>
      </div>
    );
  }

  return (
    <div className="py-4 px-3">
      <div className="w-full p-4 flex flex-col gap-1.5 justify-between border-solid border-slate-200 border-[1px] rounded-[8px]">
        <h2 className="font-grotesk text-[1rem]">
          {reactiveSuccess ? (
            "Successful!"
          ) : isCanceled ? (
            "We'll miss you..."
          ) : (
            <>Upgrade to {subscriptionInfo?.title || "Standard"}</>
          )}
        </h2>
        {reactiveSuccess ? (
          <>
            <div>
              <div className="w-fit border border-slate200 bg-slate50 rounded-[6px] px-1.5 py-[1px] flex items-center gap-1">
                <ColorBoxIcon
                  bg="bg-[rgba(247,254,231,1)]"
                  border="border-[rgba(20,83,45,1)]"
                />
                <span className="text-slate-900 text-[12px] font-medium">
                  Reactivated
                </span>
              </div>
              <div className="text-slate-500 text-xs font-medium mt-1.5">
                Your membership was successfully reactivated!
              </div>
            </div>
            <Button
              onClick={() => setReactiveSuccess(false)}
              variant="outline"
              className="!h-10 mt-2.5"
            >
              Dismiss
            </Button>
          </>
        ) : isCanceled ? (
          <>
            <div>
              <Status
                status={subscriptionStatus}
                subscription={activeSubscription?.[0]}
              />
              <div className="text-slate-500 text-xs font-medium mt-1.5">
                You will lose your data and access. Reconsider!
              </div>
            </div>

            <CustomButton
              className="w-full mt-[0.625rem]"
              onClick={() => handleReactivateSubscription()}
              // loading={stripeUpdateLoading}
              disabled={stripeUpdateLoading}
            >
              {stripeUpdateLoading ? "Reactivating..." : "Reactivate plan"}
            </CustomButton>
          </>
        ) : (
          <>
            <div className="px-2 bg-slate-50 border-solid border-[1px] border-slate-200 rounded-[6px] w-fit">
              <span className="text-[0.875rem] font-[500] text-slate-900">
                +{upgradeSellerCount || DEFAULT_SUBSCRIPTION_SELLER} Sellers
              </span>
            </div>

            <CustomButton
              className="w-full mt-2.5 gap-2 flex justify-center items-center"
              onClick={() => handleGetStripeSession({ type: nextPackage() })}
              loading={stripeLoading}
              disabled={stripeLoading}
            >
              Upgrade
              <RightArrowIcon />
            </CustomButton>
          </>
        )}
        {/* <p className="text-[#606060] text-[0.75rem]">
            Add more sellers today for 10% off. Use code:{" "}
            <span className="font-bold">MAX10</span>
          </p> */}
      </div>
    </div>
  );
}
